import { useMutation } from '@apollo/react-hooks';
import { CREATE_EVENT, GET_EVENTS } from 'graphql/queries';

export const useCreateEvent = () => {
  const [submitEvent, { error, loading }] = useMutation(CREATE_EVENT, {
    refetchQueries: [{ query: GET_EVENTS }], // refetch the contacts after completing
  });

  const createEvent = (input) => {
    return submitEvent({
      variables: { input },
    });
  };

  return {
    createEvent,
    loading,
    error,
  };
};
