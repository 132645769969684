import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useGetRedemptions } from 'hooks/useGetRedemptions';
import { Collapse } from '@mui/material';
import { ReviewDocuments } from '../ReviewDocuments';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { camelCaseToLabel } from 'utils';
import { useCompleteRedemptionStep } from 'hooks/useCompleteRedemptionStep';
import CircularProgress from '@mui/material/CircularProgress';

function createData(
  username,
  type,
  currentStep,
  startDate,
  completedDate,
  steps,
  currentStepNumber,
  redemptionId,
  completedSteps,
  userId,
  cognitoId
) {
  return {
    username,
    type,
    currentStep,
    startDate,
    completedDate,
    steps,
    currentStepNumber,
    redemptionId,
    completedSteps,
    userId,
    cognitoId,
  };
}

function Row({ row }) {
  const { handleCompleteRedemptionStep } = useCompleteRedemptionStep();
  const [open, setOpen] = React.useState(false);
  const [reviewDocuments, setReviewDocuments] = React.useState(false);

  const isStepComplete = (step, index) => {
    if (index < row.completedSteps.length) {
      const completedDate = row.completedSteps[index].completedDate;
      return completedDate.split('T')[0];
    }
    return false;
  };

  const currentStep = row.currentStep?.description || 'Complete';

  const prettyStartDate = row.startDate.split('T')[0];
  const prettyCompletedDate = row.completedDate
    ? row.completedDate.split('T')[0]
    : '';

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.username}
        </TableCell>
        <TableCell align="right">{camelCaseToLabel(row.type)}</TableCell>
        <TableCell align="right">
          {row.currentStep.description === 'Review documents' ? (
            <Button onClick={() => setReviewDocuments(!reviewDocuments)}>
              View Info/Documents
            </Button>
          ) : (
            <Button
              onClick={() =>
                handleCompleteRedemptionStep({ redemptionId: row.redemptionId })
              }
            >
              Next Step
            </Button>
          )}
        </TableCell>
        <TableCell align="right">{currentStep}</TableCell>
        <TableCell align="right">{prettyStartDate}</TableCell>
        <TableCell align="right">{prettyCompletedDate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Steps
              </Typography>
              <Stepper activeStep={row.currentStepNumber} alternativeLabel>
                {row.steps.map((step, index) => {
                  const labelProps = {};

                  const isRowComplete = isStepComplete(step, index);
                  if (isRowComplete) {
                    labelProps.optional = (
                      <Typography variant="caption" color="green">
                        Completed on {isRowComplete}
                      </Typography>
                    );
                  }

                  return (
                    <Step key={index}>
                      <StepLabel {...labelProps}>{step.description}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {reviewDocuments && (
        <ReviewDocuments
          userId={row.userId}
          redemptionId={row.redemptionId}
          cognitoId={row.cognitoId}
          currentStep={row.currentStep}
        />
      )}
    </React.Fragment>
  );
}

export function Redemptions() {
  let rows = [];

  const { redemptions, loading } = useGetRedemptions();

  redemptions.map((redemption) => {
    const {
      firstName,
      lastName,
      startDate,
      completedDate,
      steps,
      service,
      currentStep,
      currentStepNumber,
      redemptionId,
      completedSteps,
      userId,
      cognitoId,
    } = redemption;
    let username = `${firstName} ${lastName}`;

    var stepsToShow = steps.reduce(function (filtered, option) {
      if (option.display) {
        filtered.push(option);
      }
      return filtered;
    }, []);
    let res = createData(
      username,
      service,
      currentStep,
      startDate,
      completedDate,
      stepsToShow,
      currentStepNumber,
      redemptionId,
      completedSteps,
      userId,
      cognitoId
    );
    rows.push(res);
    return null;
  });

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ m: 3, paddingBottom: '48px' }}>
          <Typography variant="h5">Redeemed Services</Typography>
          <Typography variant="h5">Add filter for type</Typography>
          <Typography variant="h5">
            Add filter for current steps responsibility
          </Typography>
          <TableContainer sx={{ marginTop: 4 }} component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>User</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Action</TableCell>
                  <TableCell align="right">Current Step</TableCell>
                  <TableCell align="right">Started Date</TableCell>
                  <TableCell align="right">Completed Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <Row key={index} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
}
