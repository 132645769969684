import React, { Fragment, useMemo } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { events } from './Events';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from 'react-big-calendar';
// import DemoLink from '../../DemoLink.component';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as dates from 'utils/dates';

const mLocalizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });

export const EventsCalendar = ({
  groups,
  localizer = mLocalizer,
  showDemoLink = true,
  ...props
}) => {
  const admin = groups.includes('Admin');
  const navigate = useNavigate();

  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(2022, 8, 1),
      max: dates.add(dates.endOf(new Date(2022, 12, 12), 'day'), -1, 'hours'),
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  );
  return (
    <Box p={4} sx={{ marginBottom: '64px' }}>
      {admin && (
        <Button onClick={() => navigate('/add-event')}>+ Add Event</Button>
      )}
      <Typography>Add checkbox with event/rental to filter</Typography>
      <Typography>
        Onclick of event shows details, onclick of rental has details + link to
        direct chat them in discord
      </Typography>

      <Calendar
        components={components}
        defaultDate={defaultDate}
        events={events}
        localizer={localizer}
        max={max}
        showMultiDayTimes
        step={60}
        views={views}
        style={{ height: '75vh' }}
      />
    </Box>
  );
};
