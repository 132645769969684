import React from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Menu,
  MenuItem,
  // Grid,
  IconButton,
  Box,
  Select,
  Typography,
} from '@mui/material';

import { useIntl } from 'react-intl';
import {
  BritishFlag,
  PortugalFlag,
  FrenchFlag,
  ItalianFlag,
  GermanFlag,
} from 'styles/assets/icons';
// import { MenuVerticalIcon } from 'styles/assets/svgs';
// import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSetLocale } from 'locale/LocaleProvider';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { Discord } from 'styles/assets/svgs';

import { MenuToolbar, Heading } from './TopNavBarStyles';
import { About } from 'components/pages/About';
import { FormattedMessage } from 'react-intl';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const TopNavBar = ({ masterUser }) => {
  // const bigScreen = useMediaQuery('(min-width:800px)');

  const setLocale = useSetLocale();
  const hrefTag = `https://wa.me/351918675300?text=Ask us anything`;
  const discord = 'https://discord.gg/XpdUtkeR';

  const { locale } = useIntl();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleOpenModal = () => {
    setOpenModal(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const logout = (event) => {
    Auth.signOut();
    setAnchorEl(null);
  };

  const myRef = React.createRef();

  return (
    <Box boxShadow={3}>
      <About
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
      />
      <MenuToolbar
        variant="dense"
        sx={{
          position: 'fixed' /* Set the navbar to fixed position */,
          top: 0 /* Position the navbar at the top of the page */,
          width: '100%',
          height: '48px',
        }}
      >
        <Heading variant="h4">NEW LOCALS</Heading>

        {process.env.REACT_APP_VERSION === 2 && (
          <Select
            id="language"
            name="language"
            value={locale}
            onChange={(value) => {
              setLocale(value.target.value);
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                // Removes border on selected element
                border: 'none',
              },
            }}
          >
            <MenuItem value="en">
              <BritishFlag />
            </MenuItem>
            <MenuItem value="pt">
              <PortugalFlag />
            </MenuItem>
            <MenuItem value="ge">
              <GermanFlag />
            </MenuItem>
            <MenuItem value="fr">
              <FrenchFlag />
            </MenuItem>
            <MenuItem value="it">
              <ItalianFlag />
            </MenuItem>
          </Select>
        )}
        <IconButton
          edge="end"
          aria-controls="user-menu"
          aria-label="open user-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon
            sx={{ color: 'white', strokeWidth: '0.5px', stroke: '#000000' }}
          />
        </IconButton>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          ref={myRef}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <a
            href="https://landing.newlocals.eu"
            target="_blank"
            rel="noreferrer"
          >
            <MenuItem>
              <Typography sx={{ color: 'black' }}>Blog</Typography>
            </MenuItem>
          </a>
          {process.env.REACT_APP_VERSION === 2 && (
            <>
              <a href={discord} target="_blank" rel="noopener noreferrer">
                <MenuItem>
                  <IconButton sx={{ width: '40px', height: '40px' }}>
                    <Discord sx={{ fill: 'black' }} />
                  </IconButton>
                  <Typography sx={{ color: 'black' }}>Discord</Typography>
                </MenuItem>
              </a>
              <a href={hrefTag} target="_blank" rel="noopener noreferrer">
                <MenuItem>
                  <WhatsAppIcon sx={{ fill: '#25D366' }} />
                  <Typography sx={{ color: 'black' }}>WhatsApp</Typography>
                </MenuItem>
              </a>
            </>
          )}

          <MenuItem onClick={handleOpenModal}>
            <FormattedMessage id="about" defaultMessage="About" />
          </MenuItem>
          {masterUser?.onboarded && (
            <MenuItem
              component={Link}
              to="/privacy-policy"
              onClick={handleClose}
            >
              <FormattedMessage id="privacy" defaultMessage="Privacy Policy" />
            </MenuItem>
          )}
          {masterUser?.onboarded && (
            <MenuItem
              component={Link}
              to="/terms-and-conditions"
              onClick={handleClose}
            >
              T&C
            </MenuItem>
          )}
          <MenuItem onClick={logout}>
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </MenuItem>
        </Menu>
      </MenuToolbar>
    </Box>
  );
};
