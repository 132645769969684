import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import { SignedOutBottomNavBar } from 'components/layout/nav/BottomNavBar/SignedOutNavBar';
import RoutesComponent from './components/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import theme from './styles/theme';
import { SignedOutTopNavBar } from 'components/layout/nav/TopNavBar/SignedOutTopNavbar';
// import { ApolloProvider } from '@apollo/client';
// import getClient from 'services/apollo';
import { Auth, Hub } from 'aws-amplify';
import { Box } from '@mui/material';

import { Authenticator } from '@aws-amplify/ui-react';
import { LocaleProvider } from './locale/index';
import ErrorBoundary from './ErrorBoundary';
import AppApolloProvider from 'services/api/AppApolloProvider';

const LanguageContext = React.createContext('en');

export const App = () => {
  let [user, setUser] = useState(null);
  useEffect(() => {
    let updateUser = async (authState) => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch {
        setUser(null);
      }
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => {
      window.scrollTo(0, document.body.scrollHeight);
      Hub.remove('auth', updateUser);
    }; // cleanup
  }, []);

  return (
    <>
      <ErrorBoundary>
        {!user && (
          <>
            <SignedOutTopNavBar />
            <SignedOutBottomNavBar />
          </>
        )}

        <Authenticator
          loginMechanisms={['email']}
          socialProviders={['google']}
          variation="modal"
        >
          <LocaleProvider>
            <AppApolloProvider>
              <ThemeProvider theme={theme}>
                <SnackbarProvider
                  maxSnack={3}
                  classes={{
                    variantSuccess: 'alert success',
                    variantError: 'alert error',
                    variantInfo: 'alert info',
                    variantWarning: 'alert warning',
                    containerAnchorOriginTopCenter: 'z-alert',
                  }}
                >
                  <LanguageContext.Provider value={'en'}>
                    <Router>
                      <Box sx={{ marginTop: '48px' }}>
                        <CssBaseline />
                        <RoutesComponent />
                      </Box>
                    </Router>
                  </LanguageContext.Provider>
                </SnackbarProvider>
              </ThemeProvider>
            </AppApolloProvider>
          </LocaleProvider>
        </Authenticator>
      </ErrorBoundary>
    </>
  );
};
