export const stubbedUser = {
  services: [
    {
      type: 'nif',
      currentStep: 5,
      startedDate: new Date().toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
      completedDate: new Date().toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
      user: {
        id: '1234-5678-9012',
        firstName: 'Nicholas',
        middleName: 'Alexander',
        lastName: 'Hall',
        email: 'nickhall122@gmail.com',
      },
      steps: [
        {
          description: 'Certify passport',
          requiredDocuments: ['passport'],
          requiredFields: ['firstName', 'lastName', 'email'],
          completedDate: new Date().toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        },
        {
          description: 'Power of attorney',
          requiredDocuments: ['powerOfAttorneyForm'],
          requiredFields: ['firstName', 'lastName', 'email'],
          completedDate: new Date().toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        },
        {
          description: 'Submit to Financas',
          requiredDocuments: [
            'powerOfAttorney',
            'certifiedPassport',
            'proofOfAddress', // in home country
          ],
          requiredFields: ['firstName', 'lastName', 'email'],
          // user has status
        },
        {
          description: 'Request password',
          requiredDocuments: [],
          requiredFields: [],
          // user has status
        },
        {
          description: 'Change Address', // Do at beginning of month to give yourself time to apply for NHR
          // this gives you fiscal residency and now you have 1 year to apply for NHR
          requiredDocuments: [],
          requiredFields: [],
          // user has status
        },
      ],
    },
    {
      type: 'sns',
      currentStep: 2,
      startedDate: new Date().toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
      completedDate: '',
      user: {
        id: '1234-5678-9012',
        firstName: 'Nicholas',
        middleName: 'Alexander',
        lastName: 'Hall',
        email: 'nickhall122@gmail.com',
      },
      steps: [
        {
          description: 'Certify passport',
          requiredDocuments: ['passport'],
          requiredFields: ['firstName', 'lastName', 'email'],
          startedDate: new Date().toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          completedDate: new Date().toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        },
        {
          description: 'Power of attorney',
          requiredDocuments: ['powerOfAttorneyForm'],
          requiredFields: ['firstName', 'lastName', 'email'],
          startedDate: new Date().toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          completedDate: new Date().toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        },
        {
          description: 'Submit to Financas',
          requiredDocuments: [
            'powerOfAttorney',
            'certifiedPassport',
            'proofOfAddress', // in home country
          ],
          requiredFields: ['firstName', 'lastName', 'email'],
          // user has status
        },
        {
          description: 'Request password',
          requiredDocuments: [],
          requiredFields: [],
          // user has status
        },
        {
          description: 'Change Address', // Do at beginning of month to give yourself time to apply for NHR
          // this gives you fiscal residency and now you have 1 year to apply for NHR
          requiredDocuments: [],
          requiredFields: [],
          // user has status
        },
        {
          description: 'More Stuff', // Do at beginning of month to give yourself time to apply for NHR
          // this gives you fiscal residency and now you have 1 year to apply for NHR
          requiredDocuments: [],
          requiredFields: [],
          // user has status
        },
        {
          description: 'More Stuff', // Do at beginning of month to give yourself time to apply for NHR
          // this gives you fiscal residency and now you have 1 year to apply for NHR
          requiredDocuments: [],
          requiredFields: [],
          // user has status
        },
      ],
    },
  ],
  users: [
    {
      id: '1234-5678-9012',
      firstName: 'Nicholas',
      middleName: 'Alexander',
      lastName: 'Hall',
      email: 'nickhall122@gmail.com',
      sns: '111111111',
      nif: '302516018',
      banking: [
        {
          name: 'Santander',
          iban: 'PT50 0008 0008 0008 0008',
          longForm: 'This is the long form you occasionally need',
        },
        {
          name: 'BPI',
          iban: 'PT50 0002 0002 0002 0007',
          longForm: 'This is the long form you occasionally need',
        },
      ],
      address: {
        street: 'Rua de Sao Felix 20',
        apt: 'rc',
        city: 'Lisbon',
        state: 'Lisbon',
        zip: '1100-123',
        googleLink: 'https://goo.gl/maps/bbNs4paUVSxvbNdX6',
        mailingAddress:
          'Rua de Sao Felix 20, apt rc \nLisbon, Portugal, 1200-841',
      },
    },
    {
      id: '8421-5678-9012',
      firstName: 'Carolyn',
      middleName: 'Ruth',
      lastName: 'Commons',
      email: 'crcommons@gmail.com',
      sns: '22222222',
      nif: '302222222',
      banking: [
        {
          name: 'Chase',
          iban: 'PT50 0008 0002 0002 0002',
          longForm: 'This is the long form you occasionally need',
        },
        {
          name: 'N26',
          iban: 'PT50 0002 0002 0002 0001',
          longForm: 'This is the long form you occasionally need',
        },
      ],
      address: {
        street: 'Rua de Sao Felix 20',
        apt: 'rc',
        city: 'Lisbon',
        state: 'Lisbon',
        zip: '1100-123',
        googleLink: 'https://goo.gl/maps/bbNs4paUVSxvbNdX6',
        mailingAddress:
          'Rua de Sao Felix 20, apt rc \nLisbon, Portugal, 1200-841',
      },
    },
  ],
};
