import React from 'react';
import { Button, Fade, Modal } from '@mui/material';
import { useStyles } from './AboutStyles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const About = ({ openModal, handleCloseModal }) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <h5 className={classes.title}>New Locals App</h5>
            <h5
              className={classes.buildNo}
            >{`BUILD v${process.env.REACT_APP_VERSION}`}</h5>
            <h5 className={classes.date}>
              {process.env.REACT_APP_DATE ? process.env.REACT_APP_DATE : '-'}
            </h5>
            <h5 className={classes.text}>Developed by Carolick productions</h5>
            <div className={classes.buttonSection}>
              <Button variant="text">
                <FormattedMessage id="help" defaultMessage="HELP" />
              </Button>
              <Button variant="text" onClick={handleCloseModal}>
                <FormattedMessage id="close" defaultMessage="CLOSE" />
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

About.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func,
};
