import { useQuery } from '@apollo/react-hooks';
import { GET_MASTER_USER } from 'graphql/queries';

export const useGetMasterUser = () => {
  // pass with context instead
  const { data: masterUser, loading } = useQuery(GET_MASTER_USER);

  return {
    masterUser: masterUser?.getMasterUser || [],
    loading,
  };
};
