import React, { useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AddService } from './AddService';
import { UserServices } from './UserServices';
import { stubbedUser } from 'utils/stubbedData';

export const Services = ({ masterUser }) => {
  const [page, setPage] = useState(
    masterUser.completedPurchases[0] ? 'UserServices' : 'AddService'
  );

  const updatePage = (event, page) => {
    page && setPage(page);
  };

  return (
    <Box p={4} sx={{ paddingBottom: '64px' }}>
      <ToggleButtonGroup
        value={page}
        exclusive
        onChange={updatePage}
        aria-label="text alignment"
      >
        <ToggleButton value="AddService" aria-label="left aligned">
          Add Service
        </ToggleButton>
        <ToggleButton value="UserServices" aria-label="left aligned">
          Purchased Services
        </ToggleButton>
      </ToggleButtonGroup>
      {page === 'AddService' && <AddService />}
      {page === 'UserServices' && (
        <UserServices stubbedUser={stubbedUser} masterUser={masterUser} />
      )}
    </Box>
  );
};
