import { useMutation } from '@apollo/react-hooks';
import { CREATE_REDEMPTION, GET_MASTER_USER } from 'graphql/queries';

export const useCreateRedemption = () => {
  const [createRedemption, { error, loading }] = useMutation(
    CREATE_REDEMPTION,
    {
      refetchQueries: [{ query: GET_MASTER_USER }], // refetch the user or at least change cache to onboarded
    }
  );

  const handleCreateRedemption = (input) => {
    return createRedemption({
      variables: { input },
    });
  };

  return {
    handleCreateRedemption,
    loading,
    error,
  };
};
