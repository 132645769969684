import React from 'react';
import { IconButton, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyTextToClipboard } from 'utils';
import GoogleIcon from '@mui/icons-material/Google';
import IosShareIcon from '@mui/icons-material/IosShare';

export const UserInfo = ({ user }) => {
  const shareable = navigator.share ? true : false;

  const shareData = async (text) => {
    await navigator.share({
      title: 'Sharing data from new locals',
      text: text,
    });
  };

  return (
    <>
      <br />
      <br />
      {shareable ? (
        <>
          <Button
            onClick={() =>
              // TODO need to clean this up with apartment or not and put in country
              shareData(
                `${user.address.street}, ${user.address.apartment} \n${user.address.city}, ${user.address.country} ${user.address.zip}`
              )
            }
          >
            Mailing address
            <IconButton sx={{ width: '20px', ml: 2, mr: 2 }}>
              <IosShareIcon />
            </IconButton>
          </Button>
          <br />
          {user.address.googleLink && (
            <Button onClick={() => shareData(user.address.googleLink)}>
              Google link
              <GoogleIcon sx={{ width: '20px', ml: 2, mr: 2 }} />
            </Button>
          )}
        </>
      ) : (
        <>
          <Button
            onClick={() =>
              copyTextToClipboard(
                `${user.address.street}, ${user.address.apartment} \n${user.address.city}, ${user.address.country} ${user.address.zip}`
              )
            }
          >
            Mailing address
            <ContentCopyIcon sx={{ width: '20px', ml: 2, mr: 2 }} />
          </Button>
          <br />
          {user.address.googleLink && (
            <Button
              onClick={() => copyTextToClipboard(user.address.googleLink)}
            >
              Address Google link
              <GoogleIcon sx={{ width: '20px', ml: 2, mr: 2 }} />
            </Button>
          )}
        </>
      )}
    </>
  );
};
