import * as Sentry from '@sentry/react';
import Toaster from '../utils/toasterConfig';
import HTTP_ERRORS from './httpErrors';
import OPERATION_CONTEXT from './operationContext';

const DEFAULT_NETWORK_ERROR_MESSAGE = 'There was an error.';
const DEFAULT_EVENT_ERROR_MESSAGE = 'There was an error.';
const DEFAULT_CONTEXT_MESSAGE = 'complete the operation';

export const HANDLED_ERROR = '__HANDLED__';

export function captureAppMessage(errorMessage) {
  if (errorMessage && !process.env.REACT_APP_TESTING_MODE) {
    Sentry.captureMessage(errorMessage);
  }
}

function captureAppException(exception) {
  if (exception && !process.env.REACT_APP_TESTING_MODE) {
    Sentry.captureException(exception);
  }
}

function showUserAlert(errorMessage) {
  Toaster.error(errorMessage);
}

function getNetworkErrorMessage(statusCode) {
  return `${
    HTTP_ERRORS[statusCode]?.errorMessage || DEFAULT_NETWORK_ERROR_MESSAGE
  }`;
}

function getContextErrorMessage(operation) {
  if (!operation) {
    return DEFAULT_CONTEXT_MESSAGE;
  }

  const context =
    OPERATION_CONTEXT[operation]?.operationMessage || DEFAULT_CONTEXT_MESSAGE;

  return `${'We were not able to'} ${context}`;
}

function getNextUserStepMessage(statusCode) {
  const nextStepMessage = HTTP_ERRORS[statusCode]?.nextStepMessage;
  if (nextStepMessage) {
    return nextStepMessage;
  }
  return '';
}

export function handleAppServerError(exception, operation) {
  if (!exception) {
    return;
  }

  // eslint-disable-next-line no-console
  console.error(`Request/Operation error: ${operation}`);

  const { statusCode } = exception;
  const errorMessage = `
    ${getNetworkErrorMessage(statusCode)}
    ${getContextErrorMessage(operation)}
    ${getNextUserStepMessage(statusCode)}
  `;

  showUserAlert(errorMessage || exception.message);
  captureAppException(exception);
}

export function handleAppEventError(
  exception,
  errorMessage = DEFAULT_EVENT_ERROR_MESSAGE,
  options = { silence: false }
) {
  if (!exception) {
    return;
  }

  if (exception.message === HANDLED_ERROR) {
    return;
  }

  if (errorMessage && !options.silence) {
    showUserAlert(errorMessage);
  }

  // eslint-disable-next-line no-console
  console.error(exception.message || exception);

  captureAppException(exception);
}
