import { makeStyles, withStyles } from '@mui/styles';
import { Fab } from '@mui/material';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'grid',
    columnGap: 3,
    rowGap: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    // [theme.breakpoints.up('md')]: {
    //   gridTemplateColumns: 'repeat(3, 1fr)',
    // },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'rgba(255,255,246)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    width: '600px',
  },
  title: { fontWeight: 300 },
  buildNo: {
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Roboto Mono',
  },
  text: { fontWeight: 300, color: 'rgba(0, 0, 0, 0.6)' },
  date: {
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Roboto Mono',
  },
  buttonSection: { float: 'right' },
  cancelButton: { padding: '4px' },
  submitButton: { padding: '4px' },
}));

export const StyledFab = withStyles((theme) => ({
  root: {
    position: 'fixed !important',
    right: '40px',
    bottom: '80px',
  },
}))(Fab);
