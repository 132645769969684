export const events = [
  {
    title: 'Nick - Lapa - 2br',
    type: 'rental',
    details: {
      apartment: {
        price: '$1,000',
        br: '2',
      },
      car: {},
    },
    allDay: true,
    start: new Date(2022, 8, 10),
    end: new Date(2022, 8, 22),
  },
  {
    title: 'Saghar - Graca - 2br',
    allDay: true,
    start: new Date(2022, 8, 2),
    end: new Date(2022, 8, 15),
  },
  {
    title: 'Late Night Event',
    start: new Date(2022, 8, 17, 19, 30, 0),
    end: new Date(2022, 8, 18, 2, 0, 0),
  },

  {
    title: 'Meetup',
    type: 'event',
    start: new Date(2022, 8, 17, 19, 0, 0),
    end: new Date(2022, 8, 17, 22, 0, 0),
  },
];
