import { useMutation } from '@apollo/react-hooks';
import {
  COMPLETE_REDEMPTION_STEP,
  GET_REDEMPTIONS,
  GET_USER,
} from 'graphql/queries';

export const useCompleteRedemptionStep = () => {
  const [completeRedemptionStep, { error, loading }] = useMutation(
    COMPLETE_REDEMPTION_STEP,
    {
      refetchQueries: [{ query: GET_REDEMPTIONS }, { query: GET_USER }], // refetch the user or at least change cache to onboarded
    }
  );

  const handleCompleteRedemptionStep = (input) => {
    return completeRedemptionStep({
      variables: { input },
    });
  };

  return {
    handleCompleteRedemptionStep,
    loading,
    error,
  };
};
