import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Calendar, Team } from 'styles/assets/svgs';
import { useIntl } from 'react-intl';
import TaskIcon from '@mui/icons-material/Task';
export const BottomNavBarAdmin = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  // history.location.pathname.substring(1);
  const [value, setValue] = React.useState(location.pathname.substring(1));
  const handleChange = (event, newValue) => {
    navigate(`/${newValue}`);
    setValue(newValue);
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#438496',
        zIndex: 8,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
        sx={{
          background: '#438496',
          '& .Mui-selected': {
            color: 'rgba(255,255,246)',
          },
        }}
      >
        <BottomNavigationAction
          label="Redemptions"
          value="redemptions"
          icon={
            <TaskIcon
              color={
                value === 'redemptions' || value === ''
                  ? 'rgba(255,255,246)'
                  : 'black'
              }
            />
          }
        />

        <BottomNavigationAction
          label="Contacts"
          value="contacts"
          icon={
            <Team
              color={value === 'contacts' ? 'rgba(255,255,246)' : 'black'}
            />
          }
        />
        {/* <BottomNavigationAction
          label={intl.formatMessage({ id: 'calendar' })}
          value="calendar"
          icon={
            <Calendar
              color={value === 'calendar' ? 'rgba(255,255,246)' : 'black'}
            />
          }
        /> */}
      </BottomNavigation>
    </Paper>
  );
};
