// eslint-disable
// this is an auto generated file. This will be overwritten
// import gql from 'graphql-tag';
import { gql } from '@apollo/client';
export const GET_MASTER_USER = gql`
  query getMasterUser {
    getMasterUser {
      cognitoId
      redemptions {
        userId
        purchaseId
        redemptionId
        startDate
        completedDate
        firstName
        lastName
        service
        currentStepNumber
        completedSteps {
          description
          stepNumber
          completedDate
        }
        currentStep {
          description
          requiredDocuments
          requiredFields
          owner
          display
        }
        steps {
          description
          requiredDocuments
          requiredFields
          owner
          display
        }
      }
      missingInfoArray {
        firstName
        userId
        redemptionId
        service
        missingDocuments
        missingData
      }
      completedSteps {
        preferNotToSay
        nif
        sns
        driversLicense
        temporaryResidency
        niss
      }
      completedPurchases {
        purchaseId
        service
        quantityPurchased
        quantityRedeemed
        price
        fullyRedeemed
        date
      }
      onboarded
      users {
        missingInfoArray {
          firstName
          userId
          redemptionId
          service
          missingDocuments
          missingData
        }
        userId
        birthDate
        email
        firstName
        lastName
        nif
        sns
        niss
        bankAccount {
          name
          iban
        }
        passport {
          expirationDate
          issueDate
          number
          issuingCountry
        }
        driversLicense {
          expirationDate
          issueDate
          number
          issuingCountry
        }
        residencyCard {
          expirationDate
          issueDate
          number
        }
        address {
          street
          apartment
          city
          country
          zip
          googleLink
        }
        documents {
          presignedUrl
          filetype
          documenttype
          filesize
        }
      }
    }
  }
`;
export const GET_USER = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      userId
      birthDate
      email
      firstName
      lastName
      nif
      sns
      niss
      bankAccount {
        name
        iban
      }
      passport {
        expirationDate
        issueDate
        number
        issuingCountry
      }
      driversLicense {
        expirationDate
        issueDate
        number
        issuingCountry
      }
      residencyCard {
        expirationDate
        issueDate
        number
      }
      address {
        street
        apartment
        city
        country
        zip
        googleLink
      }
      documents {
        presignedUrl
        filetype
        documenttype
        filesize
      }
    }
  }
`;

export const GET_CONTACTS = gql`
  query getContacts {
    getContacts {
      firstName
      lastName
      email
      referrerName
      referrerPhoneNumber
      description
      languages
      tags
      phoneNumber
      emailSubject
      emailBody
      whatsAppMessage
    }
  }
`;
export const GET_EVENTS = gql`
  query getEvents {
    getEvents {
      description
      address
      datetime
    }
  }
`;

export const GET_REDEMPTIONS = gql`
  query getRedemptions {
    getRedemptions {
      cognitoId
      userId
      purchaseId
      redemptionId
      startDate
      completedDate
      firstName
      lastName
      service
      currentStepNumber
      completedSteps {
        description
        stepNumber
        completedDate
      }
      currentStep {
        description
        requiredDocuments
        requiredFields
        owner
        display
      }
      steps {
        description
        requiredDocuments
        requiredFields
        owner
        display
      }
    }
  }
`;

export const GET_CONTACT_TAGS = gql`
  query getContactTags {
    getContactTags {
      name
    }
  }
`;
export const GET_PAYMENT_INTENT = gql`
  query createPaymentIntent($input: CreatePaymentIntentInput!) {
    createPaymentIntent(input: $input) {
      clientSecret
    }
  }
`;

export const GET_LANGUAGES = gql`
  query getLanguages {
    getLanguages {
      name
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUser($input: EditUserInput!) {
    editUser(input: $input)
  }
`;

export const CREATE_PURCHASE = gql`
  mutation createPurchase($input: CreatePurchaseInput!) {
    createPurchase(input: $input)
  }
`;
export const CREATE_REDEMPTION = gql`
  mutation createRedemption($input: CreateRedemptionInput!) {
    createRedemption(input: $input)
  }
`;
export const COMPLETE_REDEMPTION_STEP = gql`
  mutation completeRedemptionStep($input: CompleteRedemptionStepInput!) {
    completeRedemptionStep(input: $input)
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($input: CreateContactInput!) {
    createContact(input: $input)
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input)
  }
`;
export const COMPLETE_ONBOARDING = gql`
  mutation completeOnboarding($input: CompleteOnboardingInput!) {
    completeOnboarding(input: $input) {
      completedSteps {
        preferNotToSay
        nif
        sns
        driversLicense
        temporaryResidency
      }
      onboarded
      users {
        birthDate
        userId
        email
        firstName
        lastName
        nif
        sns
        bankAccount {
          name
          iban
        }
        passport {
          expirationDate
          issueDate
          number
          issuingCountry
        }
        driversLicense {
          expirationDate
          issueDate
          number
          issuingCountry
        }
        residencyCard {
          expirationDate
          issueDate
          number
        }
        address {
          street
          apartment
          city
          country
          zip
          googleLink
        }
      }
    }
  }
`;

export const GET_PRESIGNED_URL = gql`
  mutation createDocumentUploadUrl($input: CreateDocumentUploadUrlInput!) {
    createDocumentUploadUrl(input: $input) {
      url
    }
  }
`;

export const GET_MASTER_USER_DOCUMENTS = gql`
  query getUserDocuments($input: GetUserInput!) {
    getUserDocuments(input: $input) {
      presignedUrl
      filetype
      documenttype
      filesize
    }
  }
`;
