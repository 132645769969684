import { useQuery } from '@apollo/react-hooks';
import { GET_CONTACT_TAGS } from 'graphql/queries';

export const useGetContactTags = () => {
  const { data: contactTags, loading } = useQuery(GET_CONTACT_TAGS);

  return {
    tagOptions: contactTags?.getContactTags || [],
    loading,
  };
};
