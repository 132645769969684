import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Settings } from 'styles/assets/svgs';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
// import { useIntl } from 'react-intl';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import ChatIcon from '@mui/icons-material/Chat';
// import useMediaQuery from '@mui/material/useMediaQuery';

export const BottomNavBar = () => {
  // const bigScreen = useMediaQuery('(min-width:800px)');
  // const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname.substring(1));
  // const hrefTag = `https://wa.me/351918675300?text=Ask us anything`;
  // const discord = 'https://discord.gg/XpdUtkeR';
  const handleChange = (event, newValue) => {
    if (newValue !== 'whatsapp' && newValue !== 'discord') {
      navigate(`/${newValue}`);
      setValue(newValue);
    }
  };
  // const hrefTag = `https://wa.me/351918675300?text=Ask us anything`;

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#438496',
        zIndex: 8,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
        sx={{
          background: '#438496',
          '& .Mui-selected': {
            color: 'rgba(255,255,246)',
          },
        }}
      >
        <BottomNavigationAction
          label="Profile"
          value="profile"
          icon={
            <SettingsIcon
              sx={{
                color: value === 'profile' ? 'rgba(255,255,246)' : 'black',
                strokeWidth: '1px',
                stroke: '#000000',
              }}
            />
          }
        />
        <BottomNavigationAction
          label="Services"
          value="services"
          icon={
            <AddIcon
              sx={{
                color: value === 'services' ? 'rgba(255,255,246)' : 'black',
                strokeWidth: '1px',
                stroke: '#000000',
              }}
            />
          }
        />
        <BottomNavigationAction
          label="Contacts"
          value="contacts"
          icon={
            <PersonIcon
              sx={{
                color: value === 'contacts' ? 'rgba(255,255,246)' : 'black',
                strokeWidth: '1px',
                stroke: '#000000',
              }}
            />
          }
        />
        {/* {process.env.REACT_APP_VERSION === 2 && (
          <BottomNavigationAction
            label={intl.formatMessage({ id: 'calendar' })}
            value="calendar"
            sx={{ color: 'rgba(255,255,246)' }}
            icon={
              <Calendar
                color={value === 'calendar' ? 'rgba(255,255,246)' : 'black'}
              />
            }
          />
        )} */}

        {/* {bigScreen && (
          <>
            <BottomNavigationAction
              label="Discord"
              value="discord"
              icon={
                <a
                  href={discord}
                  // style={{ 'text-decoration': 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ChatIcon sx={{ fill: 'black' }} />
                </a>
              }
            />
            <BottomNavigationAction
              label="WhatsApp Us"
              value="whatsapp"
              icon={
                <a
                  href={hrefTag}
                  // style={{ 'text-decoration': 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppIcon sx={{ fill: '#25D366' }} />
                </a>
              }
            />
          </>
        )} */}
      </BottomNavigation>
    </Paper>
  );
};
