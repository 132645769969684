import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'rgba(255,255,246)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
  title: { fontWeight: 300 },
  buildNo: {
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Roboto Mono',
  },
  text: { fontWeight: 300, color: 'rgba(0, 0, 0, 0.6)' },
  date: {
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Roboto Mono',
  },
  buttonSection: { float: 'right' },
}));
