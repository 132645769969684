import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { initAmplify } from './services/amplify';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

initAmplify();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? 1,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
serviceWorkerRegistration.register();
