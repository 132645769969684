import React, { useState } from 'react';

import {
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Modal,
} from '@mui/material';
import { ServiceModal } from './ServiceModal';
import { useStyles } from './ServiceModalStyles';

export const ServiceCard = ({ service }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <ServiceModal service={service} setOpenModal={setOpenModal} />
      </Modal>

      <Card
        sx={{ width: 300, m: 1, display: 'inline-block' }}
        key={service.name}
      >
        <CardMedia
          component="img"
          alt={service.label}
          height="140"
          image={service.image}
        />
        <CardContent>
          <Typography
            gutterBottom
            // variant="h6"
            sx={{ fontWeight: 500, fontSize: '1.1 rem !important' }}
            component="div"
          >
            {service.label}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Cost: €{service.cost}
          </Typography>
        </CardContent>
        <CardActions>
          {/* <Button size="small">Share</Button> */}
          <Button size="small" onClick={() => setOpenModal(true)}>
            Learn More
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
