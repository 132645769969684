import React, { useState, useEffect } from 'react';
import { IconButton, Box, Button, Typography } from '@mui/material';
import { useGetPresignedUrl } from 'hooks/useGetPresignedUrl';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
// import Toaster from 'utils/toasterConfig';
import { useSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import { camelCaseToLabel } from 'utils';
import { useUserCompletesRedemptionStep } from 'hooks/useUserCompletesRedemptionStep';
import CircularProgress from '@mui/material/CircularProgress';

export const UploadDocument = ({ missingInfo, missingDocument }) => {
  const { handlePresignedUrl } = useGetPresignedUrl();
  const { enqueueSnackbar } = useSnackbar();
  const { handleUserCompleteRedemptionStep } = useUserCompletesRedemptionStep();

  const { firstName, userId, service } = missingInfo;
  const [cognitoId, setCognitoId] = useState(null);
  const [preview, setPreview] = useState(null);
  const [fileType, setFileType] = React.useState('');
  const [presignedUrl, setPresignedUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let cognitoId = user?.attributes?.sub;
        setCognitoId(cognitoId);
      })
      .catch(() => Auth.federatedSignIn());
  }, [cognitoId]);
  function clearFile() {
    setFile(null);
  }

  function isFileTypeValid(e) {
    return !!e.target.files[0].type;
  }

  const uploadDocument = async () => {
    setUploading(true);
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    try {
      await axios
        .put(presignedUrl, file, options)
        .then((res) => {
          enqueueSnackbar(`${firstName} ${missingDocument} uploaded`, {
            variant: 'success',
          });
        })
        .then(() => {
          // Need to either refetch getUser/getUserDocs or just update the cache
          setFile('');
          setPresignedUrl(null);

          // if there is no missing data and only one missing document, you completed
          if (
            !missingInfo.missingData[0] &&
            missingInfo.missingDocuments.length === 1
          ) {
            enqueueSnackbar(
              `Thanks for uploading everything for ${missingInfo.firstName}'s ${missingInfo.service}. We're on it now!`,
              {
                variant: 'success',
                autoHideDuration: 2000,
              }
            );
            handleUserCompleteRedemptionStep({
              redemptionId: missingInfo.redemptionId,
            });
          }
          setUploading(false);
          // TODO super hacky way of handling this. Need to update the cache here
          window.location.reload();
        });
    } catch {
      enqueueSnackbar(`Error uploading ${firstName}'s ${missingDocument} `, {
        variant: 'error',
      });
    }
  };

  const handleChangedFile = async (e) => {
    e.target.files[0].type === 'application/pdf'
      ? setFileType('pdf')
      : setFileType('image');
    if (isFileTypeValid(e)) {
      const newImage = URL.createObjectURL(e.target.files[0]);

      setPreview(newImage);
      setFile(e.target.files[0]);
      let docType = missingDocument;
      if (docType === '' || docType === null) {
        alert('Please select a document type');
      } else {
        const input = {
          filename: e.target.files[0].name ?? '',
          filesize: e.target.files[0].size.toString() ?? '0',
          filetype: e.target.files[0].type ?? 'image/jpeg',
          cognitoId: cognitoId,
          userId,
          documentType: missingDocument,
        };

        const response = await handlePresignedUrl(input);

        const responseUrl = response.data.createDocumentUploadUrl.url;
        setPresignedUrl(responseUrl);
      }
    }
  };
  const alt = `preview-${firstName}-${service}-${missingDocument}`;
  const uploadId = `upload-${firstName}-${service}-${missingDocument}`;
  // Navigator.share can share an array of files
  return (
    <>
      {file && fileType === 'image' && (
        <>
          <br />
          <img
            alt={alt}
            src={preview}
            style={{ width: '100px', height: '100px' }}
          />
        </>
      )}
      {file && fileType === 'pdf' && <embed src={preview}></embed>}
      {file ? (
        <>
          <IconButton sx={{ m: 0 }} onClick={() => clearFile()}>
            <DeleteForeverIcon sx={{ color: '#ef5350' }} />
          </IconButton>
          <Button
            sx={{ m: 0 }}
            onClick={() => uploadDocument()}
            disabled={!presignedUrl || uploading}
          >
            {uploading ? (
              <CircularProgress />
            ) : (
              <>
                <SaveIcon /> {missingInfo.firstName}'s{' '}
                {camelCaseToLabel(missingDocument)}
              </>
            )}
          </Button>
        </>
      ) : (
        <Box sx={{ display: 'inline' }}>
          <Typography variant="h6" sx={{ display: 'inline' }}>
            - {camelCaseToLabel(missingDocument)}
          </Typography>

          <input
            id={uploadId}
            accept=".jpg,.jpeg,.heic, .pdf"
            type="file"
            style={{ display: 'none' }}
            onChange={handleChangedFile}
          />
          <Button>
            <label htmlFor={uploadId} style={{ cursor: 'pointer' }}>
              <FileUploadIcon sx={{ verticalAlign: 'bottom' }} />
              UPLOAD
            </label>
          </Button>
        </Box>
      )}
    </>
  );
};
