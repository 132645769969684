import React from 'react';

import { IconButton, Box } from '@mui/material';

import { MenuVerticalIcon } from 'styles/assets/svgs';

import { MenuToolbar, Heading } from './TopNavBarStyles';

export const SignedOutTopNavBar = () => {
  return (
    <Box boxShadow={3}>
      <MenuToolbar
        variant="dense"
        sx={{
          position: 'fixed' /* Set the navbar to fixed position */,
          top: 0 /* Position the navbar at the top of the page */,
          width: '100%',
          height: '48px',
        }}
      >
        <Heading variant="h4">NEW LOCALS</Heading>

        <IconButton
          edge="end"
          aria-controls="user-menu"
          aria-label="open user-menu"
          aria-haspopup="true"
        >
          <MenuVerticalIcon />
        </IconButton>
      </MenuToolbar>
    </Box>
  );
};
