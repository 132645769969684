import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Chip } from '@mui/material';
// import { useGetContactTags } from 'hooks/useGetContactTags';

export const LanguagesAutocomplete = ({
  languageOptions,
  languages,
  setLanguages,
}) => {
  const [allLanguages, setAllLanguages] = useState([]);
  const [newLanguage, setNewLanguage] = useState('');

  const handleEnterPress = (e) => {
    if (e.keyCode === 13 && newLanguage.trim()) {
      const newLanguages = [...new Set([...allLanguages, newLanguage])];
      setLanguages(newLanguages);
    }
  };
  const handleBlur = () => {
    if (newLanguage.trim()) {
      const newLanguages = [...new Set([...allLanguages, newLanguage])];
      setLanguages(newLanguages);
    }
  };
  const handleRemoveLanguage = (value) => {
    const filteredLanguages = [
      ...new Set(allLanguages.filter((tag) => tag !== value) || []),
    ];
    setLanguages(filteredLanguages);
  };

  useEffect(() => {
    setAllLanguages(languages);
  }, [languages]);

  return (
    <Autocomplete
      multiple
      id="language-tags"
      sx={{ m: 1, maxWidth: '500px' }}
      value={allLanguages}
      options={languageOptions}
      noOptionsText={'Press enter to create new language'}
      onChange={(e, value) => {
        setLanguages(value);
      }}
      getOptionLabel={(option) => option}
      renderTags={(languageValue) =>
        languageValue.map((option, index) => (
          <Chip
            label={option}
            key={index.toString()}
            onDelete={() => handleRemoveLanguage(option)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Languages'}
          fullWidth
          onKeyDown={handleEnterPress}
          onBlur={handleBlur}
          onChange={(e) => setNewLanguage(e.target.value)}
        />
      )}
    />
  );
};
