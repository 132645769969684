import { useMutation } from '@apollo/react-hooks';
import { GET_PRESIGNED_URL } from 'graphql/queries';

export const useGetPresignedUrl = () => {
  const [getPresignedUrl, { error, loading }] = useMutation(GET_PRESIGNED_URL);

  const handlePresignedUrl = (input) => {
    return getPresignedUrl({
      variables: { input },
    });
  };

  return {
    handlePresignedUrl,
    loading,
    error,
  };
};
