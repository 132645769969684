import React, { useState, useEffect } from 'react';
import { IconButton, Autocomplete, TextField, Button } from '@mui/material';
import { useGetPresignedUrl } from 'hooks/useGetPresignedUrl';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import { camelCaseToLabel } from 'utils';
import CircularProgress from '@mui/material/CircularProgress';
// import Toaster from 'utils/toasterConfig';
import { useGetUserDocuments } from 'hooks/useGetUserDocuments';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { DocsTable } from './DocsTable';

export const UserDocs = ({ user }) => {
  const { handlePresignedUrl } = useGetPresignedUrl();
  const { enqueueSnackbar } = useSnackbar();
  const { userDocuments } = useGetUserDocuments({ userId: user.userId });

  const documentTypeOptions = ['passport', 'license', 'sns', 'nif', 'other'];

  const [cognitoId, setCognitoId] = useState(null);
  const [preview, setPreview] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [documentName, setDocumentName] = useState('');
  const [documentType, setDocumentType] = React.useState('');
  const [fileType, setFileType] = React.useState('');
  const [presignedUrl, setPresignedUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let cognitoId = user?.attributes?.sub;
        setCognitoId(cognitoId);
      })
      .catch(() => Auth.federatedSignIn());
  }, [cognitoId]);

  function clearFile() {
    setFile(null);
  }

  function isFileTypeValid(e) {
    return !!e.target.files[0].type;
  }

  const uploadDocument = async () => {
    // upload presigned url with file
    setUploading(true);
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    try {
      await axios
        .put(presignedUrl, file, options)
        .then((res) => {
          enqueueSnackbar(`${user.firstName}'s ${documentType} uploaded`, {
            variant: 'success',
          });
        })
        .then(() => {
          // need to add the file and route to user query
          setFile('');
          setPresignedUrl(null);
          setDocumentType('');
        })
        .then(() => {
          // const docs = useGetUserDocuments(user.userId);
          // setDocs(docs);
        });
    } catch {
      enqueueSnackbar(`Error uploading ${user.firstName}'s ${documentType} `, {
        variant: 'error',
      });
    }
  };

  const handleChangedFile = async (e, doctype) => {
    e.target.files[0].type === 'application/pdf'
      ? setFileType('pdf')
      : setFileType('image');
    if (isFileTypeValid(e)) {
      const newImage = URL.createObjectURL(e.target.files[0]);

      setPreview(newImage);
      setFile(e.target.files[0]);
      let docType = documentType === 'other' ? documentName : documentType;
      if ((docType === '' || docType === null) && doctype === undefined) {
        alert('Please select a document type');
      } else {
        const input = {
          filename: e.target.files[0].name ?? '',
          filesize: e.target.files[0].size.toString() ?? '0',
          filetype: e.target.files[0].type ?? 'image/jpeg',
          cognitoId: cognitoId,
          userId: user.userId, // pk is cognito id, sk is document${userId}, name is documentType, add filepath
          documentType: docType || doctype || '',
        };

        const response = await handlePresignedUrl(input);

        const responseUrl = response.data.createDocumentUploadUrl.url;
        setPresignedUrl(responseUrl);
      }
    }
  };
  // Navigator.share can share an array of files
  return (
    <>
      <br />
      {!file && (
        <>
          <Autocomplete
            value={documentType}
            onChange={(event, newValue) => {
              setDocumentType(newValue);
            }}
            getOptionLabel={(option) => camelCaseToLabel(option)}
            sx={{ width: '210px', display: 'inline-block' }}
            isOptionEqualToValue={(option, value) =>
              value === option || value === ''
            }
            options={documentTypeOptions}
            renderInput={(params) => <TextField {...params} label="Doc Type" />}
          />
          {documentType === 'other' && (
            <TextField
              id="documentName"
              label="Document Name"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              sx={{
                width: '190px',
                display: 'inline-block',
                marginTop: '18px',
              }}
            />
          )}
        </>
      )}

      {file && fileType === 'image' && (
        <>
          <br />
          <img
            alt="preview"
            src={preview}
            style={{ width: '100px', height: '100px' }}
          />
        </>
      )}
      {file && fileType === 'pdf' && <embed src={preview}></embed>}
      {file ? (
        <>
          <>
            <IconButton sx={{ m: 0 }} onClick={() => clearFile()}>
              <DeleteForeverIcon sx={{ color: '#ef5350' }} />
            </IconButton>
            <Button
              sx={{ m: 0 }}
              onClick={() => uploadDocument()}
              disabled={!presignedUrl}
            >
              {uploading ? (
                <CircularProgress />
              ) : (
                <>
                  <SaveIcon />
                  {camelCaseToLabel(documentType)}
                </>
              )}
            </Button>
          </>
        </>
      ) : (
        <>
          <input
            id="upload-document"
            accept=".jpg,.jpeg,.heic, .pdf"
            type="file"
            style={{ display: 'none' }}
            onChange={handleChangedFile}
          />
          <Button
            disabled={documentType === '' || documentType === null}
            sx={{ marginTop: '26px' }}
          >
            <label htmlFor="upload-document" style={{ cursor: 'pointer' }}>
              <FileUploadIcon sx={{ verticalAlign: 'bottom' }} />
              UPLOAD
            </label>
          </Button>
        </>
      )}

      {userDocuments && (
        <DocsTable
          userDocuments={userDocuments}
          setDocumentType={setDocumentType}
          handleChangedFile={handleChangedFile}
        />
      )}
    </>
  );
};
