import { useQuery } from '@apollo/react-hooks';
import { GET_CONTACTS } from 'graphql/queries';

export const useGetContacts = () => {
  const { data: contacts, loading } = useQuery(GET_CONTACTS);

  return {
    contacts: contacts?.getContacts || [],
    loading,
  };
};
