import React, { useState, useEffect } from 'react';
// import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Route, Routes } from 'react-router-dom';
import { TopNavBar } from 'components/layout/nav/TopNavBar';
import { BottomNavBar } from 'components/layout/nav/BottomNavBar';
import { BottomNavBarAdmin } from 'components/layout/nav/BottomNavBar/BottomNavBarAdmin';
import { EventsCalendar } from 'components/pages/Calendar/EventsCalendar';
import { Services } from 'components/pages/Services';
import { Profile } from 'components/pages/Profile';
import { PrivacyPolicy } from 'components/pages/PrivacyPolicy';
import { TermsAndConditions } from 'components/pages/TermsAndConditions';
import { Contacts } from 'components/pages/Contacts';
import { ContactForm } from 'components/pages/Contacts/ContactForm';
import { EventForm } from 'components/pages/Calendar/EventForm';
import { useGetMasterUser } from 'hooks/useGetMasterUser';
import { UserForm } from 'components/pages/UserForm/UserForm';
// import { Onboarding } from './pages/Onboarding';
import { Redemptions } from './adminPages/Redemptions';
import { GetService } from './pages/Services/GetService';
import { MissingDocuments } from './pages/MissingDocuments';
import { Box } from '@mui/material';
import { useCreatePaymentIntent } from 'hooks/useCreatePaymentIntent';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MissingInfoAlert } from './MissingInfoAlert';
import { CircularProgress } from '@mui/material';
import { Auth } from 'aws-amplify';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useCompleteOnboarding } from 'hooks/useCompleteOnboarding';

// import useMediaQuery from '@mui/material/useMediaQuery';
// import { Discord } from 'styles/assets/svgs';
const stripePromise = loadStripe(
  'pk_test_51LItcXAfPuUIT1xpAHP1BUbh9oA89rppQGhhQXSz2zjLWBlHDLsjUk1iQQvRKP8tXfz4u9vaYZW4g8gAVNnVWILz00hn9Wfpld'
);

const RoutesComponent = () => {
  // const bigScreen = useMediaQuery('(min-width:800px)');
  const { masterUser, loading } = useGetMasterUser();
  const [clientSecret, setClientSecret] = useState('');
  const { handlePaymentIntent } = useCreatePaymentIntent();
  const { missingInfoArray } = masterUser;
  let [cognitoId, setCognitoId] = useState(null);
  // let [email, setEmail] = useState(null);
  let [groups, setGroups] = useState([]);

  // hacky way for now of completeing onboarding if a user doesnt have it
  const { completeOnboarding } = useCompleteOnboarding();
  !masterUser &&
    completeOnboarding({
      user: null,
      completedSteps: null,
      aboutYou: null,
    });
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      let cognitoId = user?.attributes?.sub;
      // let email = user?.attributes?.email;
      let groups =
        user?.signInUserSession.accessToken.payload['cognito:groups'] ?? [];
      setCognitoId(cognitoId);
      // setEmail(email);
      setGroups(groups);
    });
    // .catch(() => Auth.federatedSignIn());
  }, [cognitoId]);
  // const hrefTag = `https://wa.me/351918675300?text=Ask us anything`;
  // const discord = 'https://discord.gg/XpdUtkeR';

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    async function fetchData() {
      const input = {
        service: 'nif',
        quantity: 1,
        price: 150,
      };
      const res = await handlePaymentIntent(input);
      const newSecret = res.data?.createPaymentIntent?.clientSecret;
      setClientSecret(newSecret);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  const admin = groups.includes('Admin');

  return (
    <>
      <TopNavBar masterUser={masterUser} />
      <>
        {loading && !admin ? (
          <>
            <Box sx={{ m: 2 }}>
              <CircularProgress />
            </Box>
            <BottomNavBar />
          </>
        ) : (
          <>
            {admin ? (
              <>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Redemptions masterUser={masterUser} />}
                  />
                  <Route
                    exact
                    path="/redemptions"
                    element={<Redemptions masterUser={masterUser} />}
                  />
                  {process.env.REACT_APP_VERSION === 2 && (
                    <Route
                      exact
                      path="/calendar"
                      element={<EventsCalendar groups={groups} />}
                    />
                  )}

                  <Route
                    exact
                    path="/contacts"
                    element={<Contacts groups={groups} />}
                  />

                  <Route
                    exact
                    path="/add-contact"
                    element={<ContactForm masterUser={masterUser} />}
                  />
                  <Route
                    exact
                    path="/add-event"
                    element={<EventForm masterUser={masterUser} />}
                  />
                  <Route
                    exact
                    path="/missing-documents"
                    element={
                      <MissingDocuments
                        masterUser={masterUser}
                        missingInfoArray={missingInfoArray}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}
                  />
                  <Route
                    exact
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                </Routes>

                <BottomNavBarAdmin />
                {/* Put in page that shows all redemptions - has filters */}
              </>
            ) : (
              <>
                {/* {masterUser?.onboarded ? ( */}
                <>
                  {/* {bigScreen && (
                      <>
                        <a
                          href={discord}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            position: 'fixed',
                            bottom: '70px',
                            right: '30px',
                            color: '#438496',
                          }}
                        >
                          <Fab
                            aria-label="whatsapp"
                            variant="contained"
                            sx={{}}
                          >
                            <WhatsAppIcon sx={{ fill: '#25D366' }} />
                          </Fab>
                        </a>
                        <a
                          href={hrefTag}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            position: 'fixed',
                            bottom: '140px',
                            right: '30px',
                            color: '#438496',
                          }}
                        >
                          <Fab
                            aria-label="chaticon"
                            variant="contained"
                            sx={{ zIndex: '9999' }}
                          >
                            <IconButton sx={{ width: '55px', height: '55px' }}>
                              <Discord sx={{ fill: 'black' }} />
                            </IconButton>
                          </Fab>
                        </a>
                      </>
                    )} */}
                  {missingInfoArray[0] &&
                    missingInfoArray.map((missingInfo, index) => {
                      return (
                        <React.Fragment key={index}>
                          <MissingInfoAlert missingInfo={missingInfo} />
                        </React.Fragment>
                      );
                    })}
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <Routes>
                        <Route
                          exact
                          path="/"
                          element={<Profile masterUser={masterUser} />}
                        />
                        <Route
                          exact
                          path="/calendar"
                          element={<EventsCalendar groups={groups} />}
                        />

                        <Route
                          exact
                          path="/contacts"
                          element={<Contacts groups={groups} />}
                        />
                        <Route
                          exact
                          path="/profile"
                          element={<Profile masterUser={masterUser} />}
                        />

                        <Route
                          exact
                          path="/edit-user/:id"
                          element={<UserForm masterUser={masterUser} />}
                        />

                        <Route
                          exact
                          path="/services"
                          element={<Services masterUser={masterUser} />}
                        />
                        <Route
                          exact
                          path="/get-service/:service"
                          element={<GetService masterUser={masterUser} />}
                        />

                        <Route
                          exact
                          path="/add-user"
                          element={<UserForm masterUser={masterUser} />}
                        />

                        <Route
                          exact
                          path="/missing-documents"
                          element={
                            <MissingDocuments
                              masterUser={masterUser}
                              missingInfoArray={missingInfoArray}
                            />
                          }
                        />

                        <Route
                          exact
                          path="/privacy-policy"
                          element={<PrivacyPolicy />}
                        />
                        <Route
                          exact
                          path="/terms-and-conditions"
                          element={<TermsAndConditions />}
                        />
                      </Routes>
                    </Elements>
                  )}
                  <BottomNavBar />
                </>
                {/* ) : (
                  // if not onboarded, you get initial quesitons, limited top nav, no bottom nav
                  <>
                    <Onboarding email={email} />
                  </> */}
                {/* )} */}
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default RoutesComponent;
