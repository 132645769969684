import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const Save = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <path
        fill="none"
        clipRule="evenodd"
        d="M21.75 23.45H2.25a1.5 1.5 0 01-1.5-1.5V7.443a3 3 0 01.879-2.121l3.492-3.493A3 3 0 017.243.95H21.75a1.5 1.5 0 011.5 1.5v19.5a1.5 1.5 0 01-1.5 1.5z"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M8.25.95v6a1.5 1.5 0 001.5 1.5h7.5a1.5 1.5 0 001.5-1.5v-6M15.75 3.95v1.5"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        clipRule="evenodd"
        d="M17.25 12.95H6.75a1.5 1.5 0 00-1.5 1.5v9h13.5v-9a1.5 1.5 0 00-1.5-1.5z"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M8.25 15.95h4.5M8.25 18.95h7.5"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
