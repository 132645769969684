import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const Add = ({ color }) => {
  return (
    <SvgIcon viewBox="0 0 24 25">
      <path
        d="M0 11.0489C0 11.8031 0.671573 12.4144 1.5 12.4144H10.25C10.3881 12.4144 10.5 12.5163 10.5 12.642V20.6073C10.5 21.3615 11.1716 21.9728 12 21.9728C12.8284 21.9728 13.5 21.3615 13.5 20.6073V12.642C13.5 12.5163 13.6119 12.4144 13.75 12.4144H22.5C23.3284 12.4144 24 11.8031 24 11.0489C24 10.2948 23.3284 9.68343 22.5 9.68343H13.75C13.6119 9.68343 13.5 9.58154 13.5 9.45585V1.49049C13.5 0.736351 12.8284 0.125 12 0.125C11.1716 0.125 10.5 0.736351 10.5 1.49049V9.45585C10.5 9.58154 10.3881 9.68343 10.25 9.68343H1.5C0.671573 9.68343 0 10.2948 0 11.0489Z"
        fill={color}
      />
    </SvgIcon>
  );
};
