import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const Team = ({ color }) => {
  return (
    <SvgIcon viewBox="0 0 24 25">
      <svg
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 4C9.10457 4 10 3.10457 10 2C10 0.89543 9.10457 0 8 0C6.89543 0 6 0.89543 6 2C6 3.10457 6.89543 4 8 4Z"
          fill={color}
          fillOpacity="0.87"
        />
        <path
          d="M11.89 6.11C11.5 5.72 10.83 5 9.53 5C9.32 5 8.11 5 6.99 5C4.24 4.99 2 2.75 2 0H0C0 3.16 2.11 5.84 5 6.71V20H7V14H9V20H11V8.05L14.95 12L16.36 10.59L11.89 6.11Z"
          fill={color}
          fillOpacity="0.87"
        />
      </svg>
    </SvgIcon>
  );
};
