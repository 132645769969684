import { useQuery } from '@apollo/react-hooks';
import { GET_MASTER_USER_DOCUMENTS } from 'graphql/queries';

export const useGetUserDocuments = (input) => {
  const { data: userDocuments } = useQuery(GET_MASTER_USER_DOCUMENTS, {
    variables: {
      input,
    },
  });

  return {
    userDocuments: userDocuments?.getUserDocuments || [],
  };
};
