import React from 'react';
import { COLORS } from '../../colors';

// const { typeLight } = COLORS;

export const MenuVerticalIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.987 23.253C13.4367 23.253 14.612 22.0778 14.612 20.628C14.612 19.1783 13.4367 18.003 11.987 18.003C10.5373 18.003 9.362 19.1783 9.362 20.628C9.362 22.0778 10.5373 23.253 11.987 23.253Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.987 6.00301C13.4367 6.00301 14.612 4.82775 14.612 3.37801C14.612 1.92826 13.4367 0.753006 11.987 0.753006C10.5373 0.753006 9.362 1.92826 9.362 3.37801C9.362 4.82775 10.5373 6.00301 11.987 6.00301Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.987 14.628C13.4367 14.628 14.612 13.4528 14.612 12.003C14.612 10.5533 13.4367 9.37801 11.987 9.37801C10.5373 9.37801 9.362 10.5533 9.362 12.003C9.362 13.4528 10.5373 14.628 11.987 14.628Z"
        fill="white"
      />
    </svg>
  );
};
