import { useMutation } from '@apollo/react-hooks';
import { EDIT_USER, GET_MASTER_USER } from 'graphql/queries';

export const useEditUser = () => {
  const [submitUser, { error, loading }] = useMutation(EDIT_USER, {
    refetchQueries: [{ query: GET_MASTER_USER }], // refetch the user or at least change cache to onboarded
  });

  const editUser = (input) => {
    return submitUser({
      variables: { input },
    });
  };

  return {
    editUser,
    loading,
    error,
  };
};
