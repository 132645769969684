import { useMutation } from '@apollo/react-hooks';
import { COMPLETE_ONBOARDING, GET_MASTER_USER } from 'graphql/queries';

export const useCompleteOnboarding = () => {
  const [submitOnboarding, { error, loading }] = useMutation(
    COMPLETE_ONBOARDING,
    {
      refetchQueries: [{ query: GET_MASTER_USER }], // refetch the user or at least change cache to onboarded
    }
  );

  const completeOnboarding = (input) => {
    return submitOnboarding({
      variables: { input },
    });
  };

  return {
    completeOnboarding,
    loading,
    error,
  };
};
