import * as React from 'react';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { services } from 'utils/services';
import { camelCaseToLabel } from 'utils';
import DoneIcon from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import { useCreateRedemption } from 'hooks/useCreateRedemption';
import { useNavigate } from 'react-router-dom';

const TooltipContents = ({ user, service }) => {
  const { requiredFields, requiredDocuments } = service.firstStep;
  // need to start fetching documents here

  const docs = user.documents.map(function (item) {
    return item['documenttype'];
  });

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(255,255,246)',
        border: 'none',
        margin: '0px',
        padding: '0px',
      }}
    >
      <Typography sx={{ color: 'black' }}>
        You can upload documents after you complete, but make sure that all of
        this will be available!
      </Typography>
      <br />
      {requiredFields && (
        <Typography variant="h6" sx={{ color: 'black' }}>
          Required Data
        </Typography>
      )}
      {requiredFields &&
        requiredFields.map((data, index) => (
          <React.Fragment key={index}>
            {user[data] ? (
              <DoneIcon sx={{ color: '#438496' }} />
            ) : (
              <Typography sx={{ color: 'red', display: 'inline' }}>
                {' '}
                -{'  '}
              </Typography>
            )}
            <Typography
              key={index}
              color={user[data] ? '#438496' : 'red'}
              sx={{ display: 'inline' }}
            >
              {camelCaseToLabel(data)}
            </Typography>
            <br />
          </React.Fragment>
        ))}
      {requiredDocuments && (
        <Typography variant="h6" sx={{ color: 'black' }}>
          Required Documents
        </Typography>
      )}
      {requiredDocuments &&
        requiredDocuments.map((document, index) => (
          <React.Fragment key={index}>
            {docs.includes(document) ? (
              <DoneIcon sx={{ color: '#438496' }} />
            ) : (
              <Typography sx={{ color: 'red', display: 'inline' }}>
                {' '}
                -{'  '}
              </Typography>
            )}
            <Typography
              sx={{ display: 'inline' }}
              color={docs.includes(document) ? '#438496' : 'red'}
            >
              {camelCaseToLabel(document)}
            </Typography>
            <br />
          </React.Fragment>
        ))}
    </Box>
  );
};

export function UnredeemedService({ masterUser, completedPurchase }) {
  const { users } = masterUser;
  const { handleCreateRedemption, loading } = useCreateRedemption();
  const navigate = useNavigate();

  const updateUser = (event, user) => {
    setUser(user.props.value);
  };

  const service = services.find(
    (service) => service.name === completedPurchase.service
  );

  const usersWithoutService = users.filter(function (user) {
    //check if they have a purchase if this service
    const userService = masterUser.redemptions.filter(function (redemption) {
      if (
        redemption.userId === user.userId &&
        redemption.service === service.name
      ) {
        return true;
      }
      return false;
    });
    return !userService.length;
  });

  const [user, setUser] = React.useState(usersWithoutService[0] || {});

  const remainingRedemptions =
    completedPurchase.quantityPurchased - completedPurchase.quantityRedeemed;

  const apostrophe = completedPurchase.quantityPurchased > 1 ? `'s` : '';
  const submitCreateRedemption = () => {
    const input = {
      purchaseId: completedPurchase.purchaseId,
      service: service.name,
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
    };
    handleCreateRedemption(input);
  };
  return (
    <>
      <Box sx={{ marginTop: 1 }}>
        <Typography sx={{ display: 'inline' }}>
          {remainingRedemptions} of {completedPurchase.quantityPurchased}{' '}
          {completedPurchase.service}
          {apostrophe} remaining
        </Typography>
        {usersWithoutService[0] ? (
          <>
            <Select
              sx={{ width: '185px', mb: 2, mr: 1, ml: 1 }}
              labelId="user-label"
              id="user"
              defaultValue={user}
              value={user}
              onChange={updateUser}
              renderValue={() => {
                return `${user.firstName} ${user.lastName}`;
              }}
              inputProps={{ 'aria-label': 'user' }}
            >
              {usersWithoutService.map((user, index) => (
                <MenuItem value={user} key={index}>
                  {user.firstName} {user.lastName}
                </MenuItem>
              ))}
            </Select>
            <Tooltip title={<TooltipContents user={user} service={service} />}>
              <Box
                onClick={() => {
                  submitCreateRedemption();
                }}
                sx={{ bgcolor: 'rgba(255,255,246)', display: 'inline' }}
              >
                <Button>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    `Redeem ${completedPurchase.service} for ${user.firstName}`
                  )}
                </Button>
              </Box>
            </Tooltip>
          </>
        ) : (
          <>
            <Typography sx={{ display: 'inline' }}>
              . All current users have a{' '}
              {camelCaseToLabel(completedPurchase.service)} so you need to add
              one.
            </Typography>
            <Button
              sx={{ display: 'inline' }}
              onClick={() => navigate('/add-user')}
            >
              +Add User
            </Button>
          </>
        )}
      </Box>
    </>
  );
}
