import * as React from 'react';
import { Box, Collapse, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { UnredeemedService } from './UnredeemedService';
import { useNavigate } from 'react-router-dom';
import { camelCaseToLabel } from 'utils';

function createData(
  user,
  type,
  currentStep,
  startDate,
  completedDate,
  steps,
  currentStepNumber,
  redemptionId,
  completedSteps
) {
  return {
    user,
    type,
    currentStep,
    startDate,
    completedDate,
    steps,
    currentStepNumber,
    redemptionId,
    completedSteps,
  };
}

function Row({ row, masterUser }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const missingInfo = masterUser.missingInfoArray.filter(function (element) {
    return element.redemptionId === row.redemptionId;
  });

  const isStepComplete = (step, index) => {
    if (row.completedSteps && index < row.completedSteps.length) {
      const completedDate = row.completedSteps[index].completedDate;
      return completedDate.split('T')[0];
    }
    return false;
  };
  const isCurrentStep = (step, index) => {
    if (row.currentStep.description === step.description) {
      return true;
    }
    return row.currentStep === index;
  };

  const currentStep = row.currentStep?.description || 'Complete';

  const prettyStartDate = row.startDate.split('T')[0];
  const prettycompletedDate = row.completedDate
    ? row.completedDate.split('T')[0]
    : '';
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.user}
        </TableCell>
        <TableCell align="right">{camelCaseToLabel(row.type)}</TableCell>
        <TableCell align="right">{currentStep}</TableCell>
        <TableCell align="right">{prettyStartDate}</TableCell>
        <TableCell align="right">{prettycompletedDate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 4 }}>
              <Stepper activeStep={row.currentStepNumber} alternativeLabel>
                {row.steps.map((step, index) => {
                  const labelProps = {};

                  const isRowComplete = isStepComplete(step, index);
                  if (isRowComplete) {
                    labelProps.optional = (
                      <Typography variant="caption" color="green">
                        Completed on {isRowComplete}
                      </Typography>
                    );
                  }
                  if (
                    isCurrentStep(step, index) &&
                    row.currentStep.owner === 'USER' &&
                    missingInfo[0]
                  ) {
                    labelProps.optional = (
                      <>
                        {missingInfo[0].missingDocuments &&
                          missingInfo[0].missingDocuments[0] && (
                            <Box key={index}>
                              <Button
                                onClick={() => navigate(`/missing-documents`)}
                                variant="text"
                                sx={{
                                  display: 'block',
                                  paddingLeft: 1,
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }}
                                size="small"
                              >
                                UPLOAD DOCS{' '}
                              </Button>
                            </Box>
                          )}

                        {missingInfo[0].missingData &&
                          missingInfo[0].missingData[0] && (
                            <Box sx={{ display: 'inline' }}>
                              {/* <Box sx={{ display: 'inline' }}>
                                {missingInfo[0].missingData.join(', ')}
                              </Box> */}

                              <Button
                                onClick={() =>
                                  navigate(
                                    `/edit-user/${missingInfo[0].userId}`
                                  )
                                }
                                variant="text"
                                sx={{
                                  display: 'block',
                                  paddingLeft: 1,
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                }}
                                size="small"
                              >
                                EDIT INFO
                              </Button>
                            </Box>
                          )}
                      </>
                    );
                  }

                  return (
                    <Step key={index}>
                      <StepLabel {...labelProps}>{step.description}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function UserServices({ stubbedUser, masterUser }) {
  let rows = [];
  masterUser.redemptions.map((redemption) => {
    const {
      firstName,
      lastName,
      startDate,
      completedDate,
      steps,
      service,
      currentStep,
      currentStepNumber,
      redemptionId,
      completedSteps,
    } = redemption;
    let username = `${firstName} ${lastName}`;
    var stepsToShow = steps.reduce(function (filtered, option) {
      if (option.display) {
        filtered.push(option);
      }
      return filtered;
    }, []);
    let res = createData(
      username,
      service,
      currentStep,
      startDate,
      completedDate,
      stepsToShow,
      currentStepNumber,
      redemptionId,
      completedSteps
    );
    rows.push(res);
    return null;
  });
  const unredeemedPurchases = masterUser.completedPurchases.filter(function (
    purchase
  ) {
    return purchase.fullyRedeemed === false;
  });

  return (
    <>
      {unredeemedPurchases[0] && (
        <Box sx={{ marginTop: 4 }}>
          {unredeemedPurchases[0] &&
            unredeemedPurchases.map((completedPurchase, index) => {
              return (
                <React.Fragment key={index}>
                  <UnredeemedService
                    completedPurchase={completedPurchase}
                    masterUser={masterUser}
                  />
                </React.Fragment>
              );
            })}
        </Box>
      )}

      <TableContainer sx={{ marginTop: 4 }} component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>User</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Current Step</TableCell>
              <TableCell align="right">Started Date</TableCell>
              <TableCell align="right">Completed Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} masterUser={masterUser} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
