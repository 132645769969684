import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Box,
  IconButton,
  Chip,
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import IosShareIcon from '@mui/icons-material/IosShare';
import { copyTextToClipboard } from 'utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const ContactCard = ({ contact, filterItems, setFilterItems }) => {
  const referrerHrefTag = `https://wa.me/${contact.referrerPhoneNumber}?text=Hey ${contact.referrerName}, \nWould you recommend ${contact.firstName} ${contact.lastName} for ***`;
  const shareable = navigator.share ? true : false;
  const hrefTag =
    contact.email &&
    `mailto:${contact.email}?cc=newlocals@gmail.com&subject=${contact.emailSubject}&body=${contact.emailBody}`;
  const phoneText = contact.phoneNumber ? `Phone: +${contact.phoneNumber}` : '';
  const emailText = contact.email ? `Email: ${contact.email}` : '';
  const copyText = `${contact.firstName} ${contact.lastName} \n${phoneText} \n${emailText}`;
  const shareData = async () => {
    await navigator.share({
      title: 'Sharing data from new locals',
      text: copyText,
    });
  };
  const whatsAppHref = `https://wa.me/${contact.phoneNumber}?text=${contact.whatsAppMessage}`;
  return (
    <Box sx={{ m: 1 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          ></Typography>
          <Typography variant="h5" component="div">
            {contact.firstName}
            {` `}
            {contact.lastName}
          </Typography>
          <Typography sx={{ mb: 0.5 }} color="text.secondary">
            {contact.description}
          </Typography>
          <Typography variant="body2" sx={{ display: 'inline' }}>
            Referred by {contact.referrerName}
          </Typography>
          <a href={referrerHrefTag} target="_blank" rel="noopener noreferrer">
            <IconButton
              aria-label="whatsapp"
              sx={{
                '& .MuiIconButton-root:hover': {
                  backgroundColor: '#ffdab9',
                },
              }}
            >
              <WhatsAppIcon />
            </IconButton>
          </a>{' '}
          <br />
          <Typography
            variant="body2"
            sx={{ display: 'inline', fontWeight: 'bold' }}
          >
            Tags:
          </Typography>
          {contact.tags.map((tag, index) => (
            <React.Fragment key={index}>
              <Chip
                key={index}
                label={tag}
                variant={filterItems.includes(tag) ? 'filled' : 'outlined'}
                onClick={() => {
                  if (filterItems.includes(tag)) {
                    setFilterItems(filterItems.filter((item) => item !== tag));
                  } else {
                    setFilterItems([...filterItems, tag]);
                  }
                }}
                sx={{
                  margin: '2px',
                  // marginRight: '2px',
                  // marginBottom: '2px',
                  // marginTop: '2px',
                  '& .MuiChip-label': {
                    padding: '6px',
                    // paddingRight: '6px',
                  },
                }}
              />
            </React.Fragment>
          ))}
          <br />
          <Typography
            variant="body2"
            sx={{ display: 'inline', fontWeight: 'bold' }}
          >
            Languages:
          </Typography>
          {contact.languages.map((language, index) => (
            <React.Fragment key={index}>
              <Chip
                label={language}
                variant={filterItems.includes(language) ? 'filled' : 'outlined'}
                onClick={() => {
                  if (filterItems.includes(language)) {
                    setFilterItems(
                      filterItems.filter((item) => item !== language)
                    );
                  } else {
                    setFilterItems([...filterItems, language]);
                  }
                }}
                sx={{
                  margin: '2px',
                  '& .MuiChip-label': {
                    padding: '6px',
                    // paddingRight: '6px',
                  },
                }}
              />
            </React.Fragment>
          ))}
          <Typography variant="body2">{contact.address} </Typography>
        </CardContent>
        <CardActions>
          {contact.phoneNumber && (
            <a
              href={whatsAppHref}
              target="_blank"
              rel="noopener noreferrer"
              style={{ margin: '0px' }}
            >
              <IconButton aria-label="whatsapp">
                <WhatsAppIcon />
              </IconButton>
            </a>
          )}
          {contact.email && (
            <a href={hrefTag} style={{ margin: '0px' }}>
              <IconButton aria-label="email">
                <EmailIcon />
              </IconButton>
            </a>
          )}
          {shareable ? (
            <IconButton aria-label="share" onClick={() => shareData()}>
              <IosShareIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => copyTextToClipboard(copyText)}>
              <ContentCopyIcon />
            </IconButton>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};
