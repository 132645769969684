import { makeStyles, withStyles } from '@mui/styles';
import { Fab } from '@mui/material';
export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'grid',
    columnGap: 3,
    rowGap: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
}));

export const StyledFab = withStyles((theme) => ({
  root: {
    position: 'fixed !important',
    right: '40px',
    bottom: '80px',
  },
}))(Fab);
