import { withStyles } from '@mui/styles';
import { Toolbar, Typography, Select } from '@mui/material';

export const Heading = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: 'rgba(255,255,246)',
    fontSize: '1.125rem !important',
    letterSpacing: '0.15px',
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
  },
}))(Typography);

export const StyledSelect = withStyles((theme) => ({
  root: {
    '& MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}))(Select);

export const MenuToolbar = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#438496',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'visible',
    zIndex: 8,
    height: '48px',
  },
}))(Toolbar);
