import React from 'react';
import { Box, TextField, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { camelCaseToLabel } from 'utils';
import { useStyles } from './ModalStyles';
import { SimpleTooltip } from 'components/SimpleTooltip';

export const AddressForm = ({ formField, setFormField, handleCloseModal }) => {
  const schema = yup
    .object({
      street: yup.string().required('Street is required'),
      apartment: yup.string(),
      city: yup.string().required('City is required'),
      country: yup.string().required('Country is required'),
      zip: yup.string().required('Zip is required'),
      googleLink: yup.string(),
    })
    .required();
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      street: formField?.street ?? '',
      apartment: formField?.apartment ?? '',
      city: formField?.city ?? '',
      country: formField?.country ?? '',
      zip: formField?.zip ?? '',
      googleLink: formField?.googleLink ?? '',
    },
  });

  const onSubmit = (data) => {
    setFormField(data);
    handleCloseModal();
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <>
          {['street', 'apartment', 'city', 'country', 'zip'].map(
            (thing, index) => (
              <React.Fragment key={index}>
                <Controller
                  key={index}
                  name={thing}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={camelCaseToLabel(thing)}
                      sx={{ width: '230px', m: 2 }}
                      error={Boolean(errors[thing])}
                      helperText={errors[thing] && errors[thing]?.message}
                      {...field}
                    />
                  )}
                />
              </React.Fragment>
            )
          )}
        </>
        <br />
        <div className={classes.buttonSection}>
          <Button
            variant="text"
            color="error"
            className={classes.cancelButton}
            onClick={handleCloseModal}
          >
            CLOSE
          </Button>
          <Button
            variant="text"
            className={classes.submitButton}
            onClick={handleSubmit(onSubmit)}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </Box>
  );
};
