import React from 'react';
import { Box } from '@mui/material';
export const FilePreview = ({ userDocuments, doc }) => {
  var userDocs = userDocuments.reduce(function (filtered, option) {
    if (option.documenttype === doc) {
      filtered.push(option);
    }
    return filtered;
  }, []);
  const file = userDocs[0];
  // Navigator.share can share an array of files
  return (
    <Box sx={{ display: 'inline' }}>
      {file && file.filetype === 'image/jpeg' && (
        <>
          <img
            alt="preview"
            src={file.presignedUrl}
            style={{ width: '200px', height: '200px' }}
          />
        </>
      )}
      {file && file.filetype === 'pdf' && (
        <embed src={file.presignedUrl} width="50px" height="75px"></embed>
      )}
    </Box>
  );
};
