const HTTP_ERRORS = {
  500: {
    name: 'Internal server error',
    errorMessage: 'There was an error.',
    nextStepMessage: `That's all we know. Please try again later.`,
  },
  502: {
    name: 'Bad gateway',
    errorMessage:
      'The server encountered a temporary error and could not complete your request.',
    nextStepMessage: 'Please try again soon.',
  },
  503: {
    name: 'Service unavailable',
    errorMessage: 'The server is currently unable to handle the request.',
    nextStepMessage: 'Please try again soon.',
  },
  400: {
    name: 'Bad request',
    errorMessage: 'Your client has issued a malformed or illegal request.',
  },
  401: {
    name: 'Unauthorized',
    errorMessage: 'You are not authorized to access this page.',
    nextStepMessage: 'Sign in with a different account.',
  },
  403: {
    name: 'Forbidden',
    errorMessage: 'User not authorized to perform the operation.',
    nextStepMessage: 'Sign in with a different account.',
  },
  404: {
    name: 'Not found',
    errorMessage: 'The resource you are trying to access does not exist.',
    nextStepMessage: 'Ensure the web address is entered correctly.',
  },
};
export default HTTP_ERRORS;
