import React from 'react';
// import { COLORS } from '../../colors';
// const { typeLight } = COLORS;

export const Settings = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.544 2.63899C11.9154 3.05037 12.4437 3.28515 12.998 3.28515C13.5523 3.28515 14.0806 3.05037 14.452 2.63899L15.392 1.59699C15.9479 0.98378 16.8292 0.786076 17.5937 1.10307C18.3583 1.42006 18.8411 2.18335 18.8 3.00999L18.728 4.40999C18.6992 4.96387 18.9067 5.50397 19.2988 5.89615C19.691 6.28833 20.2311 6.49579 20.785 6.46699L22.185 6.39499C23.0139 6.35088 23.7807 6.83399 24.0987 7.60078C24.4167 8.36756 24.2169 9.25152 23.6 9.80699L22.558 10.747C22.1466 11.1184 21.9118 11.6467 21.9118 12.201C21.9118 12.7552 22.1466 13.2836 22.558 13.655L23.6 14.595C24.2157 15.1504 24.415 16.0333 24.0977 16.7993C23.7803 17.5654 23.0151 18.0487 22.187 18.006L20.787 17.934C20.2333 17.9058 19.6936 18.1135 19.3015 18.5055C18.9095 18.8976 18.7018 19.4373 18.73 19.991L18.802 21.391C18.8447 22.2191 18.3614 22.9843 17.5953 23.3017C16.8293 23.619 15.9464 23.4197 15.391 22.804L14.451 21.762C14.0796 21.3506 13.5513 21.1158 12.997 21.1158C12.4428 21.1158 11.9144 21.3506 11.543 21.762L10.6 22.805C10.0446 23.4207 9.16173 23.62 8.39567 23.3027C7.62962 22.9853 7.14633 22.2201 7.189 21.392L7.261 19.992C7.28884 19.4384 7.08107 18.8988 6.68911 18.5069C6.29715 18.1149 5.75762 17.9072 5.204 17.935L3.804 18.007C2.97839 18.0462 2.21699 17.563 1.90093 16.7993C1.58486 16.0356 1.78213 15.1557 2.394 14.6L3.436 13.66C3.84737 13.2886 4.08215 12.7602 4.08215 12.206C4.08215 11.6517 3.84737 11.1234 3.436 10.752L2.394 9.81199C1.77749 9.25675 1.57762 8.37335 1.89507 7.6068C2.21252 6.84024 2.97844 6.3568 3.807 6.39999L5.207 6.47199C5.7616 6.50054 6.30228 6.29247 6.69468 5.8995C7.08707 5.50654 7.29435 4.96556 7.265 4.41099L7.193 3.01099C7.15332 2.1852 7.63634 1.42339 8.40017 1.10705C9.164 0.790713 10.0442 0.98795 10.6 1.59999L11.544 2.63899Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.998 18.201C16.3117 18.201 18.998 15.5148 18.998 12.201C18.998 8.88734 16.3117 6.20105 12.998 6.20105C9.68429 6.20105 6.998 8.88734 6.998 12.201C6.998 15.5148 9.68429 18.201 12.998 18.201Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.998 6.20105V12.201"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.998 12.201L8.398 16.051"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.998 12.201L17.866 15.679"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
