import React from 'react';
import { Box, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyTextToClipboard } from 'utils';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useNavigate } from 'react-router-dom';

export const UserNumbers = ({ user }) => {
  const navigate = useNavigate();

  const shareable = navigator.share ? true : false;

  const shareData = async (text) => {
    await navigator.share({
      title: 'Sharing data from new locals',
      text: text,
    });
  };

  return (
    <>
      <br />

      {['nif', 'sns', 'niss'].map((field, index) => (
        <React.Fragment key={index}>
          {user[field] && (
            <Box>
              {shareable ? (
                <Button onClick={() => shareData(user[field])}>
                  {field}: {user[field]}
                  <IosShareIcon sx={{ ml: 2, mr: 2, mb: 1 }} />
                </Button>
              ) : (
                <Button onClick={() => copyTextToClipboard(user[field])}>
                  {field}: {user[field]}
                  <ContentCopyIcon sx={{ ml: 2, mr: 2, mb: 1 }} />
                </Button>
              )}
              <br />
            </Box>
          )}
        </React.Fragment>
      ))}
      {user.bankAccount && (
        <>
          {shareable ? (
            <>
              <Button onClick={() => shareData(user.bankAccount.iban)}>
                {user.bankAccount.name} IBAN
                <IosShareIcon sx={{ ml: 2, mr: 2, mb: 1 }} />
              </Button>
              <Button
                onClick={() =>
                  shareData('long form of this with swift, address, etc.')
                }
              >
                {user.bankAccount.name} Long Form
                <IosShareIcon sx={{ ml: 2, mr: 2, mb: 1 }} />
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => copyTextToClipboard(user.bankAccount.iban)}
              >
                {user.bankAccount.name} IBAN
                <ContentCopyIcon sx={{ ml: 2, mr: 2, mb: 1 }} />
              </Button>
              <Button
                onClick={() =>
                  copyTextToClipboard(
                    'long form of this with swift, address, etc.'
                  )
                }
              >
                {user.bankAccount.name} Long Form
                <ContentCopyIcon sx={{ ml: 2, mr: 2, mb: 1 }} />
              </Button>
            </>
          )}
          <br />
        </>
      )}
    </>
  );
};
