/* eslint-disable no-console */
import React from 'react';
import { services } from 'utils/services';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Button, TextField } from '@mui/material';
import { StripeCheckout } from './StripeCheckout';
import { useCreatePaymentIntent } from 'hooks/useCreatePaymentIntent';
import { camelCaseToLabel } from 'utils';
import { SimpleTooltip } from '../../SimpleTooltip';

export const GetService = ({ masterUser }) => {
  const { handlePaymentIntent } = useCreatePaymentIntent();
  const [secret, setSecret] = React.useState(null);
  const [quantity, setQuantity] = React.useState(0);
  const [purchaseInput, setPurchaseInput] = React.useState({});
  const location = useLocation();
  const serviceName = location.pathname.replace('/get-service/', '');
  const service = services.find((service) => service.name === serviceName);

  const handleClick = async () => {
    const input = {
      service: service.name,
      quantity: parseInt(quantity),
      price: parseInt(service.cost),
    };
    setPurchaseInput(input);
    const res = await handlePaymentIntent(input);
    const newSecret = res.data?.createPaymentIntent?.clientSecret;
    setSecret(newSecret);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const tooltipText =
    'Proof of address must be address outside Portugal and clearly state your name. Common documents include drivers license, utilities (within past 3 months), bank statement (within last 3 months)';
  return (
    <Box p={4} sx={{ paddingBottom: '64px' }}>
      <Box sx={{ marginBottom: 1 }}>
        <Typography variant="h6" color="text.secondary">
          {camelCaseToLabel(service.name)} Required Documents:
        </Typography>
        <ul style={{ margin: '0px' }}>
          {service.requiredDocuments.map((doc, index) => (
            <li key={index}>
              <Typography variant="paragraph" color="text.secondary">
                {camelCaseToLabel(doc)}
                {doc === 'proofOfAddressOrigin' && (
                  <SimpleTooltip text={tooltipText} />
                )}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      {service.timeEstimate && (
        <Typography variant="h5" color="text.secondary">
          Estimated Time: {service.timeEstimate}
        </Typography>
      )}
      <Typography sx={{ display: 'inline' }}>
        By continuing you agree to our{' '}
        <a
          href="https://dev.newlocals.eu/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
      </Typography>
      <br />
      <br />
      <Box sx={{ marginBottom: 1 }}>
        <TextField
          value={quantity}
          onChange={handleQuantityChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          sx={{
            width: '50px',
            marginRight: '12px',
            '& .MuiOutlineInput-input': { padding: '8.5px 16px !important' },
          }}
        />
        <Typography sx={{ display: 'inline' }}>
          X {service.cost} per {camelCaseToLabel(service.name)} ={' '}
          {parseInt(service.cost) * parseInt(quantity) || 0}
        </Typography>

        <Button
          disabled={
            isNaN(parseInt(service.cost) * parseInt(quantity)) ||
            parseInt(service.cost) * parseInt(quantity) === 0
          }
          sx={{ marginLeft: '10px', backgroundColor: '#438496' }}
          variant="contained"
          onClick={() => handleClick()}
        >
          Proceed to payment
        </Button>
      </Box>
      {secret && (
        <>
          <Typography>
            This is currently in testing, please do not type in credit card info
          </Typography>
          <StripeCheckout purchaseInput={purchaseInput} clientSecret={secret} />
        </>
      )}
    </Box>
  );
};
