import { useMutation } from '@apollo/react-hooks';
import { CREATE_CONTACT, GET_CONTACTS } from 'graphql/queries';

export const useCreateContact = () => {
  const [submitContact, { error, loading }] = useMutation(CREATE_CONTACT, {
    refetchQueries: [{ query: GET_CONTACTS }], // refetch the contacts after completing
  });

  const createContact = (input) => {
    return submitContact({
      variables: { input },
    });
  };

  return {
    createContact,
    loading,
    error,
  };
};
