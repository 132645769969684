import { useQuery } from '@apollo/react-hooks';
import { GET_LANGUAGES } from 'graphql/queries';

export const useGetLanguages = () => {
  const { data: languages, loading } = useQuery(GET_LANGUAGES);
  return {
    languageOptions: languages?.getLanguages || [],
    loading,
  };
};
