import React from 'react';
import { Box, Typography } from '@mui/material';

export const TermsAndConditions = () => {
  return (
    <Box p={6}>
      <Typography variant="h3" component="h1">
        Terms and Conditions
      </Typography>
      <Typography variant="p">Need to add a terms and conditions</Typography>
    </Box>
  );
};
