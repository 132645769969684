import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Calendar, Add, Settings, Team } from 'styles/assets/svgs';

export const SignedOutBottomNavBar = () => {
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#438496',
        zIndex: 8,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        sx={{
          background: '#438496',
          '& .Mui-selected': {
            color: 'rgba(255,255,246)',
          },
        }}
      >
        <BottomNavigationAction
          label="Profile"
          value="profile"
          icon={<Settings color={'black'} />}
        />
        <BottomNavigationAction
          label="Services"
          value="services"
          icon={<Add color={'black'} />}
        />
        <BottomNavigationAction
          label="Contacts"
          value="contacts"
          icon={<Team color={'black'} />}
        />
        {/* <BottomNavigationAction
          label={'Calendar'}
          value="calendar"
          icon={<Calendar color={'black'} />}
        /> */}
      </BottomNavigation>
    </Paper>
  );
};
