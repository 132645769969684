import { Auth } from 'aws-amplify';
import { handleAppEventError } from 'errorHandling';

const setHeaders = async (operation = {}, context = {}) => {
  const { operationName } = operation;
  const { headers } = context;
  // eslint-disable-next-line no-console

  try {
    const session = await Auth.currentSession();
    return {
      headers: {
        ...headers,
        Authorization: session ? session.idToken.jwtToken : '',
      },
    };
  } catch (err) {
    const internalMessage = `Auth Error. Before Operation: ${operationName}`;
    const exception = err || new Error(internalMessage);
    handleAppEventError(exception);
    Auth.signOut();
    throw exception; // This stops propagation to next Apollo Link, preventing doing the request
  }
};

export default setHeaders;
