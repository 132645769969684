import React from 'react';
import { Box, TextField, Stack, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { camelCaseToLabel } from 'utils';
import enGb from 'date-fns/locale/en-GB';
import { useStyles } from './UserFormStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const ResidencyCardForm = ({
  formField,
  setFormField,
  handleCloseModal,
}) => {
  const classes = useStyles();
  const schema = yup
    .object({
      number: yup.string().required('License number is required'),
      issueDate: yup.string().nullable().required('Issue date is required'),
      expirationDate: yup
        .string()
        .nullable()
        .required('Expiration date is required'),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      number: formField?.number ?? '',
      issueDate: formField?.issueDate ?? null,
      expirationDate: formField?.expirationDate ?? null,
    },
  });

  const onSubmit = (data) => {
    // eslint-disable-next-line no-console
    setFormField(data);
    handleCloseModal();
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <>
          <Controller
            key="number"
            name="number"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  label={camelCaseToLabel('number')}
                  sx={{ width: '230px', m: 2 }}
                  error={Boolean(errors.number)}
                  helperText={errors.number && errors.number?.message}
                  {...field}
                />
              );
            }}
          />

          <Controller
            render={({ field }) => {
              return (
                <LocalizationProvider
                  adapterLocale={enGb}
                  dateAdapter={AdapterDateFns}
                >
                  <Stack
                    spacing={3}
                    sx={{ width: '230px', m: 2, display: 'inline-block' }}
                  >
                    <DatePicker
                      {...field}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      label={camelCaseToLabel('issueDate')}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.issueDate)}
                          helperText={
                            errors.issueDate && errors.issueDate?.message
                          }
                        />
                      )}
                      sx={{ width: '300px' }}
                    />
                  </Stack>
                </LocalizationProvider>
              );
            }}
            name="issueDate"
            control={control}
            onChange={([, data]) => data}
            defaultValue={new Date()}
          />

          <Controller
            render={({ field }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    spacing={3}
                    sx={{ width: '230px', m: 2, display: 'inline-block' }}
                  >
                    <DatePicker
                      {...field}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      label={camelCaseToLabel('expirationDate')}
                      minDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.expirationDate)}
                          helperText={
                            errors.expirationDate &&
                            errors.expirationDate?.message
                          }
                        />
                      )}
                      sx={{ width: '300px' }}
                    />
                  </Stack>
                </LocalizationProvider>
              );
            }}
            name="expirationDate"
            control={control}
            onChange={([, data]) => data}
            defaultValue={new Date()}
          />
        </>
        <br />
        <div className={classes.buttonSection}>
          <Button
            variant="text"
            color="error"
            className={classes.cancelButton}
            onClick={handleCloseModal}
          >
            CLOSE
          </Button>
          <Button
            variant="text"
            className={classes.submitButton}
            onClick={handleSubmit(onSubmit)}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </Box>
  );
};
