import React, { useState } from 'react';
import { Box, Button, Autocomplete, Chip, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetContacts } from 'hooks/useGetContacts';
import { useGetContactTags } from 'hooks/useGetContactTags';
import { useGetLanguages } from 'hooks/useGetLanguages';
import { ContactCard } from './ContactCard';
import { useStyles } from './ContactsStyles';
import CircularProgress from '@mui/material/CircularProgress';

export const Contacts = ({ groups }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const admin = groups.includes('Admin');

  const { languageOptions } = useGetLanguages();
  const { tagOptions } = useGetContactTags();

  const languageOptionsArray = languageOptions.map(function (language) {
    return language['name'];
  });
  const tagOptionsArray = tagOptions.map(function (tag) {
    return tag['name'];
  });

  const options =
    languageOptions && tagOptions
      ? [...languageOptionsArray, ...tagOptionsArray]
      : [];

  const [filterItems, setFilterItems] = useState([]);

  const { contacts, loading } = useGetContacts();
  const filteredContacts = contacts.filter((contact) => {
    const combinedArray = [...contact.tags, ...contact.languages];
    const containsAll = filterItems.every((element) => {
      return combinedArray.indexOf(element) !== -1;
    });

    return containsAll;
  });

  const handleRemoveTag = (value) => {
    const filteredTags = [
      ...new Set(filterItems.filter((item) => item !== value) || []),
    ];
    setFilterItems(filteredTags);
  };

  return (
    <Box
      px={5}
      py={3}
      width={{
        xs: '100vw',
        md: '100vw',
        marginBottom: '48px',
        paddingBottom: '64px',
      }}
    >
      <Autocomplete
        multiple
        id="item-tags"
        sx={{ m: 1 }}
        options={options}
        value={filterItems}
        noOptionsText={'No options'}
        onChange={(e, value) => {
          setFilterItems(value);
        }}
        getOptionLabel={(option) => option}
        renderTags={(tagsValue) =>
          tagsValue.map((option, index) => (
            <Chip
              label={option}
              key={index.toString()}
              onDelete={() => handleRemoveTag(option)}
              sx={{
                '& .MuiChip-label': {
                  padding: '6px',
                  // paddingRight: '6px',
                },
              }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={'Filter'} fullWidth />
        )}
      />
      {admin && (
        <Button onClick={() => navigate('/add-contact')}>+ Add Contact</Button>
      )}

      {loading ? (
        <Box className={classes.cardContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className={classes.cardContainer}>
          {filteredContacts.map((contact, index) => (
            <React.Fragment key={index}>
              <ContactCard
                contact={contact}
                filterItems={filterItems}
                setFilterItems={setFilterItems}
              />
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
};
