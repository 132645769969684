import { useMutation } from '@apollo/react-hooks';
import { CREATE_PURCHASE, GET_MASTER_USER } from 'graphql/queries';

export const useCreatePurchase = () => {
  const [createPurchase, { error, loading }] = useMutation(CREATE_PURCHASE, {
    refetchQueries: [{ query: GET_MASTER_USER }], // refetch the user or at least change cache to onboarded
  });

  const handleCreatePurchase = (input) => {
    return createPurchase({
      variables: { input },
    });
  };

  return {
    handleCreatePurchase,
    loading,
    error,
  };
};
