import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from 'graphql/queries';

export const useGetUser = (input) => {
  const { data: user } = useQuery(GET_USER, {
    variables: {
      input,
    },
  });

  return {
    user: user?.getUser || [],
  };
};
