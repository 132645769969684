import InfoIcon from '@mui/icons-material/Info';

import Tooltip from '@mui/material/Tooltip';

export const SimpleTooltip = ({ text }) => {
  return (
    <Tooltip title={text}>
      <InfoIcon />
    </Tooltip>
  );
};
