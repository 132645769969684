import React from 'react';
import { Box, TextField, Button, Autocomplete, Stack } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { camelCaseToLabel } from 'utils';
import enGb from 'date-fns/locale/en-GB';

import { countries } from 'utils/countries';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useStyles } from './ModalStyles';

export const DriversLicenseForm = ({
  formField,
  setFormField,
  handleCloseModal,
}) => {
  const schema = yup
    .object({
      number: yup.string().required('License number is required'),
      issuingCountry: yup
        .string()
        .nullable()
        .required('Issuing country is required'),
      issueDate: yup
        .string('Issuing Date is required')
        .nullable()
        .required('Issue date is required'),
      expirationDate: yup
        .string('Expiration Date is required')
        .nullable()
        .required('Expiration date is required'),
    })
    .required();

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      number: formField?.number ?? '',
      issuingCountry: formField?.issuingCountry ?? null,
      issueDate: formField?.issueDate ?? null,
      expirationDate: formField?.expirationDate ?? null,
    },
  });

  const onSubmit = (data) => {
    setFormField(data);
    handleCloseModal();
  };
  const issuingCountryDefault = countries.filter(function (el) {
    return el.label === formField?.issuingCountry;
  });

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <>
          <Controller
            key="number"
            name="number"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  label={camelCaseToLabel('number')}
                  sx={{ width: '230px', m: 2 }}
                  error={Boolean(errors.number)}
                  helperText={errors.number && errors.number?.message}
                  {...field}
                />
              );
            }}
          />
          <Controller
            render={({ field: { onChange }, ...props }) => {
              return (
                <Autocomplete
                  id="issuingCountry"
                  sx={{ width: '230px', m: 2, display: 'inline-block' }}
                  defaultValue={issuingCountryDefault[0] || null}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={camelCaseToLabel('issuingCountry')}
                        error={Boolean(errors.issuingCountry)}
                        helperText={
                          errors.issuingCountry &&
                          errors.issuingCountry?.message
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    );
                  }}
                  onChange={(e, data) => {
                    onChange(data.label); // makes it the text instead of the object
                  }}
                />
              );
            }}
            name="issuingCountry"
            control={control}
            onChange={([, data]) => {
              return data;
            }}
          />
          <Controller
            render={({ field }) => {
              return (
                <LocalizationProvider
                  adapterLocale={enGb}
                  dateAdapter={AdapterDateFns}
                >
                  <Stack
                    spacing={3}
                    sx={{ width: '230px', m: 2, display: 'inline-block' }}
                  >
                    <DatePicker
                      {...field}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      label={camelCaseToLabel('issueDate')}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.issueDate)}
                          helperText={
                            errors.issueDate && errors.issueDate?.message
                          }
                        />
                      )}
                      sx={{ width: '300px' }}
                    />
                  </Stack>
                </LocalizationProvider>
              );
            }}
            name="issueDate"
            control={control}
            onChange={([, data]) => data}
            defaultValue={new Date()}
          />

          <Controller
            render={({ field }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    spacing={3}
                    sx={{ width: '230px', m: 2, display: 'inline-block' }}
                  >
                    <DatePicker
                      {...field}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      label={camelCaseToLabel('expirationDate')}
                      minDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.expirationDate)}
                          helperText={
                            errors.expirationDate &&
                            errors.expirationDate?.message
                          }
                        />
                      )}
                      sx={{ width: '300px' }}
                    />
                  </Stack>
                </LocalizationProvider>
              );
            }}
            name="expirationDate"
            control={control}
            onChange={([, data]) => data}
            defaultValue={new Date()}
          />
        </>
        <br />
        <div className={classes.buttonSection}>
          <Button
            variant="text"
            color="error"
            className={classes.cancelButton}
            onClick={handleCloseModal}
          >
            CLOSE
          </Button>
          <Button
            variant="text"
            className={classes.submitButton}
            onClick={handleSubmit(onSubmit)}
          >
            SUBMIT
          </Button>
        </div>

        <br />
      </form>
    </Box>
  );
};
