import React, { useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';

import en from './translations/english.json';
import pt from './translations/portuguese.json';
import ge from './translations/german.json';
import fr from './translations/french.json';
import it from './translations/italian.json';

const LanguageContext = React.createContext({});

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState('en');

  const messages = {
    en,
    pt,
    ge,
    fr,
    it,
  };

  return (
    <LanguageContext.Provider value={(locale, setLocale)}>
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useSetLocale = () => useContext(LanguageContext);
