import * as React from 'react';
import { IconButton, Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
// import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import { StyledCell } from './DocsTableStyles';
import { saveAs } from 'file-saver';
import { camelCaseToLabel } from 'utils';
import PreviewIcon from '@mui/icons-material/Preview';

function createData(documenttype, filetype, filesize, presignedUrl) {
  return {
    documenttype,
    filetype,
    filesize,
    presignedUrl,
  };
}

function Row(props) {
  const { row, setDocumentType, handleChangedFile } = props;

  const handleUpload = (e) => {
    setDocumentType(row.documenttype);
    handleChangedFile(e, row.documenttype);
  };

  const downloadDocument = (presignedUrl, filetype, documenttype) => {
    const filename = `${filetype}.${documenttype}`;
    // TODO for now just view file and download from there
    // var file = new File(['Hello, world!'], 'hello world.txt', {
    //   type: 'text/plain;charset=utf-8',
    // });
    // saveAs(file);
    saveAs(presignedUrl, filename);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {/* <StyledCell component="th" scope="row">
          <Checkbox />
        </StyledCell> */}
        <StyledCell align="center">{row.documenttype}</StyledCell>
        <StyledCell align="center">
          {row.filetype === 'application/pdf' ? (
            <embed src={row.presignedUrl} width="50px" height="75px"></embed>
          ) : (
            <img
              alt="preview"
              src={row.presignedUrl}
              style={{ width: '50px', height: '50px' }}
            />
          )}
        </StyledCell>
        <StyledCell align="center">{row.filetype}</StyledCell>
        <StyledCell align="center">{row.filesize}</StyledCell>

        <StyledCell align="center">
          <IconButton
            sx={{ m: 2 }}
            onClick={() =>
              downloadDocument(row.presignedUrl, row.documenttype, 'jpg')
            }
          >
            <PreviewIcon />
          </IconButton>
        </StyledCell>
        <StyledCell align="center">
          <Box sx={{ display: 'inline' }}>
            <input
              id={row.documenttype}
              accept=".jpg,.jpeg,.heic, .pdf"
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => handleUpload(e)}
            />
            <label htmlFor={row.documenttype}>
              <IconButton component="span">
                <EditIcon />
              </IconButton>
            </label>
          </Box>
        </StyledCell>
      </TableRow>
    </React.Fragment>
  );
}

export function DocsTable({
  userDocuments,
  setDocumentType,
  handleChangedFile,
}) {
  let rows = [];
  userDocuments.map((document) => {
    const { documenttype, filetype, filesize, presignedUrl } = document;
    let capDoc = camelCaseToLabel(documenttype);
    let res = createData(capDoc, filetype, filesize, presignedUrl);
    rows.push(res);
    return null;
  });
  return (
    <>
      <br />
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {/* <StyledCell>Checkbox</StyledCell> */}
              <StyledCell align="center" sx={{ fontWeight: 'bold' }}>
                Document
              </StyledCell>
              <StyledCell align="center" sx={{ fontWeight: 'bold' }}>
                Preview
              </StyledCell>
              <StyledCell align="center" sx={{ fontWeight: 'bold' }}>
                File Type
              </StyledCell>
              <StyledCell align="center" sx={{ fontWeight: 'bold' }}>
                File Size
              </StyledCell>

              <StyledCell align="center" sx={{ fontWeight: 'bold' }}>
                View
              </StyledCell>
              <StyledCell align="center" sx={{ fontWeight: 'bold' }}>
                Edit
              </StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row
                key={index}
                row={row}
                setDocumentType={setDocumentType}
                handleChangedFile={handleChangedFile}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
