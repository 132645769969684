import React from 'react';
import { UserService } from './UserService';
import { Box } from '@mui/material';
export const MissingDocuments = ({ missingInfoArray }) => {
  return (
    <Box sx={{ paddingBottom: '64px' }}>
      {missingInfoArray &&
        missingInfoArray.map((missingInfo, index) => {
          if (
            missingInfo?.missingDocuments &&
            missingInfo?.missingDocuments[0]
          ) {
            return (
              <React.Fragment key={index}>
                <UserService missingInfo={missingInfo} />
              </React.Fragment>
            );
          }
          return null;
        })}
    </Box>
  );
};
