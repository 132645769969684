import React, { useState } from 'react';
import { Box, CircularProgress, TextField, Autocomplete } from '@mui/material';
import { StyledFab } from './ContactsStyles';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { camelCaseToLabel } from 'utils';
import { countries } from 'utils/countries';
import { Save } from 'styles/assets/icons';
import { ContactTagsAutocomplete } from './ContactTagsAutocomplete';
import { LanguagesAutocomplete } from './LanguagesAutocomplete';
import { useGetContactTags } from 'hooks/useGetContactTags';
import { useGetLanguages } from 'hooks/useGetLanguages';
import { useCreateContact } from 'hooks/useCreateContact';

export const ContactForm = ({ contact }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tags, setTags] = useState([]);
  const [languages, setLanguages] = useState([]);
  const { createContact } = useCreateContact();
  const { tagOptions } = useGetContactTags();
  const { languageOptions } = useGetLanguages();
  const navigate = useNavigate();

  const languageOptionsArray = languageOptions.map(function (language) {
    return language['name'];
  });
  const tagOptionsArray = tagOptions.map(function (tag) {
    return tag['name'];
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const schema = yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().email('Not a valid email'), // email validation
      description: yup.string().required(),
      countryCode: yup.string(),
      phoneNumber: yup.string(), // switch to phone number validator
      referrerName: yup.string(),
      referrerEmail: yup.string().email('Not a valid email'),
      referrerCountryCode: yup.string(),
      referrerPhoneNumber: yup.string(),
      emailSubject: yup.string(),
      emailBody: yup.string(),
      whatsAppMessage: yup.string(),
      // address - then you can route to them?
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: contact?.firstName ?? '',
      lastName: contact?.lastName ?? '',
      email: contact?.email ?? '',
      phoneNumber: contact?.phoneNumber ?? '',
      referrer: contact?.referrer ?? '',
      emailSubject: contact?.emailSubject ?? '',
      emailBody: contact?.emailBody ?? '',
      whatsAppMessage: contact?.whatsAppMessage ?? '',

      // nationality: user?.nationality ?? '',
      // placeOfBirth: user?.placeOfBirth ?? '',
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);

    const {
      firstName,
      lastName,
      email,
      description,
      referrerName,
      referrerEmail,
      emailSubject,
      emailBody,
      whatsAppMessage,
    } = data;

    const phoneNumber = `${data.countryCode}${data.phoneNumber}`;
    const referrerPhoneNumber = `${data.referrerCountryCode}${data.referrerPhoneNumber}`;

    const input = {
      firstName,
      lastName,
      email,
      description,
      referrerName,
      referrerEmail,
      phoneNumber,
      referrerPhoneNumber,
      tags,
      languages,
      emailSubject,
      emailBody,
      whatsAppMessage,
    };

    createContact(input);
    navigate('/contacts');

    enqueueSnackbar(
      `Contact card for ${data.firstName} was successfully created`,
      {
        variant: 'success',
      }
    );
    setIsSubmitting(false);
  };

  return (
    <Box px={5} py={3} width={{ xs: '100vw', marginBottom: '48px' }}>
      <form onSubmit={handleSubmit}>
        {['firstName', 'lastName', 'email'].map((thing) => (
          <Box sx={{ display: 'inline-block' }} key={thing}>
            <Controller
              name={thing}
              control={control}
              render={({ field }) => (
                <TextField
                  label={camelCaseToLabel(thing)}
                  sx={{ width: '230px', m: 1 }}
                  helperText={errors[thing] && errors[thing]?.message}
                  {...field}
                />
              )}
            />
          </Box>
        ))}
        {['description'].map((thing) => (
          <Box sx={{ width: '100%' }} key={thing}>
            <Controller
              name={thing}
              control={control}
              render={({ field }) => (
                <TextField
                  label={camelCaseToLabel(thing)}
                  multiline
                  rows={2}
                  sx={{ width: '100%', m: 1, maxWidth: '500px' }}
                  helperText={errors[thing] && errors[thing]?.message}
                  {...field}
                />
              )}
            />
          </Box>
        ))}
        <Controller
          render={({ field: { onChange }, ...props }) => {
            return (
              <Autocomplete
                id="countryCode"
                sx={{ width: '230px', display: 'inline-block', m: 1 }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.phone}
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={camelCaseToLabel('countryCode')}
                      error={Boolean(errors.countryCode)}
                      helperText={
                        errors.countryCode && errors.countryCode?.message
                      }
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  );
                }}
                onChange={(e, data) => {
                  onChange(data.phone);
                }}
                // {...props}
              />
            );
          }}
          name="countryCode"
          control={control}
          onChange={([, data]) => data}
        />
        <Controller
          name={'phoneNumber'}
          control={control}
          render={({ field }) => (
            <TextField
              label={camelCaseToLabel('phoneNumber')}
              sx={{ width: '230px', m: 1 }}
              helperText={
                errors['phoneNumber'] && errors['phoneNumber']?.message
              }
              {...field}
            />
          )}
        />
        <ContactTagsAutocomplete
          tagOptions={tagOptionsArray}
          tags={tags}
          setTags={setTags}
        />
        <LanguagesAutocomplete
          languages={languages}
          setLanguages={setLanguages}
          languageOptions={languageOptionsArray}
        />
        <br />
        {['referrerName', 'referrerEmail'].map((thing) => (
          <Box sx={{ display: 'inline-block' }} key={thing}>
            <Controller
              name={thing}
              control={control}
              render={({ field }) => (
                <TextField
                  label={camelCaseToLabel(thing)}
                  sx={{ width: '230px', m: 1 }}
                  helperText={errors[thing] && errors[thing]?.message}
                  {...field}
                />
              )}
            />
          </Box>
        ))}
        {['whatsAppMessage', 'emailSubject', 'emailBody'].map((thing) => (
          <Box sx={{ width: '100%' }} key={thing}>
            <Controller
              name={thing}
              control={control}
              render={({ field }) => (
                <TextField
                  label={camelCaseToLabel(thing)}
                  multiline
                  rows={2}
                  sx={{ width: '100%', m: 1, maxWidth: '500px' }}
                  helperText={errors[thing] && errors[thing]?.message}
                  {...field}
                />
              )}
            />
          </Box>
        ))}
        <br />
        <Controller
          render={({ field: { onChange }, ...props }) => {
            return (
              <Autocomplete
                id="referrerCountryCode"
                sx={{ width: '230px', display: 'inline-block', m: 1 }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.phone}
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={camelCaseToLabel('referrerCountryCode')}
                      error={Boolean(errors.referrerCountryCode)}
                      helperText={
                        errors.referrerCountryCode &&
                        errors.referrerCountryCode?.message
                      }
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  );
                }}
                onChange={(e, data) => {
                  onChange(data.phone);
                }}
                // {...props}
              />
            );
          }}
          name="referrerCountryCode"
          control={control}
          onChange={([, data]) => data}
        />
        <Controller
          name={'referrerPhoneNumber'}
          control={control}
          render={({ field }) => (
            <TextField
              label={camelCaseToLabel('referrerPhoneNumber')}
              sx={{ width: '230px', m: 1 }}
              helperText={
                errors['referrerPhoneNumber'] &&
                errors['referrerPhoneNumber']?.message
              }
              {...field}
            />
          )}
        />
        <StyledFab
          disabled={isSubmitting}
          type="button"
          id="submit-form"
          aria-label="submit-form"
          variant="circular"
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? <CircularProgress /> : <Save />}
        </StyledFab>
      </form>
    </Box>
  );
};
