import React, { useState } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { StyledFab } from './EventStyles';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { camelCaseToLabel } from 'utils';
import { Save } from 'styles/assets/icons';

import { useCreateEvent } from 'hooks/useCreateEvent';

export const EventForm = ({ event }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { createEvent } = useCreateEvent();

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const schema = yup
    .object({
      description: yup.string().required(),
      address: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: event?.description ?? '',
      address: event?.address ?? '',

      // nationality: user?.nationality ?? '',
      // placeOfBirth: user?.placeOfBirth ?? '',
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const { address, description, datetime } = data;
    const input = {
      address,
      description,
      datetime,
    };

    createEvent(input);
    navigate('/calendar');

    enqueueSnackbar(`Event  was successfully created`, {
      variant: 'success',
    });
    setIsSubmitting(false);
  };

  return (
    <Box px={5} py={3} width={{ xs: '100vw', marginBottom: '48px' }}>
      <form onSubmit={handleSubmit}>
        {['description', 'address', 'datetime'].map((thing) => (
          <Box sx={{ width: '100%' }} key={thing}>
            <Controller
              name={thing}
              control={control}
              render={({ field }) => (
                <TextField
                  label={camelCaseToLabel(thing)}
                  multiline
                  rows={2}
                  sx={{ width: '100%', m: 1, maxWidth: '500px' }}
                  helperText={errors[thing] && errors[thing]?.message}
                  {...field}
                />
              )}
            />
          </Box>
        ))}

        <StyledFab
          disabled={isSubmitting}
          type="button"
          id="submit-form"
          aria-label="submit-form"
          variant="circular"
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? <CircularProgress /> : <Save />}
        </StyledFab>
      </form>
    </Box>
  );
};
