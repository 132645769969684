import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  TextField,
  IconButton,
  Typography,
  Modal,
  Button,
  Stack,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enGb from 'date-fns/locale/en-GB';
import { StyledFab, useStyles } from './UserFormStyles';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { camelCaseToLabel } from 'utils';
import { BankingForm } from './BankingForm';
import { Save } from 'styles/assets/icons';
import { PassportForm } from './PassportForm';
import { DriversLicenseForm } from './DriversLicenseForm';
import { ResidencyCardForm } from './ResidencyCardForm';
import { AddressForm } from './AddressForm';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
// import { AddressAutocomplete } from './AddressAutocomplete';
import { useUserCompletesRedemptionStep } from 'hooks/useUserCompletesRedemptionStep';
import { useCompleteOnboarding } from 'hooks/useCompleteOnboarding';
import { useEditUser } from 'hooks/useEditUser';
import { useLocation } from 'react-router-dom';

export const UserForm = ({
  masterUser,
  onboarding = false,
  aboutYou = null,
  completedSteps = null,
  email = null,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const editing =
    location.pathname.split('/')[1] === 'edit-user' ? true : false;
  const userId = editing ? location.pathname.split('/')[2] : null;
  const user = editing
    ? masterUser.users.filter((user) => user.userId === userId)[0]
    : null;
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  let missingFields = [];
  const helperTexts = {};
  // going through missingInfo array and adding all the fields and helper texts for service
  user?.missingInfoArray?.map((missingInfo) => {
    missingInfo.missingData.map((field) => {
      missingFields.push(field);
      helperTexts[field] = `${camelCaseToLabel(field)} is missing for ${
        missingInfo.service
      }`;
    });
  });
  const [openModal, setOpenModal] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { completeOnboarding } = useCompleteOnboarding();
  const { editUser } = useEditUser();
  const { handleUserCompletesRedemptionStep } =
    useUserCompletesRedemptionStep();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [banking, setBanking] = useState(user?.bankAccount || false);
  const [passport, setPassport] = useState(user?.passport || false);
  const [driversLicense, setDriversLicense] = useState(
    user?.driversLicense || false
  );
  const [residencyCard, setResidencyCard] = useState(
    user?.residencyCard || false
  );
  const [address, setAddress] = useState(user?.address || false);

  const modalOptions = [
    {
      component: (
        <PassportForm
          setFormField={setPassport}
          formField={passport}
          handleCloseModal={handleCloseModal}
        />
      ),
      field: passport,
      formName: 'passport',
      header: 'Passport Form',
    },
    {
      component: (
        <AddressForm
          setFormField={setAddress}
          formField={address}
          handleCloseModal={handleCloseModal}
        />
      ),
      field: address,
      formName: 'address',
      header: 'Address Form',
    },
    {
      component: (
        <DriversLicenseForm
          setFormField={setDriversLicense}
          formField={driversLicense}
          handleCloseModal={handleCloseModal}
        />
      ),
      field: driversLicense,
      formName: 'driversLicense',
      header: 'Drivers License Form',
    },
    {
      component: (
        <ResidencyCardForm
          setFormField={setResidencyCard}
          formField={residencyCard}
          handleCloseModal={handleCloseModal}
        />
      ),
      field: residencyCard,
      formName: 'residencyCard',
      header: 'Residency Card Form',
    },
    // {
    //   component: (
    //     <BankingForm
    //       setFormField={setBanking}
    //       formField={banking}
    //       handleCloseModal={handleCloseModal}
    //     />
    //   ),
    //   field: banking,
    //   formName: 'banking',
    //   header: 'Banking Form',
    // },
  ];

  const [modalForm, setModalForm] = useState(modalOptions[0]);

  const schema = yup
    .object({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      email: yup.string().email('Not a valid email'), // email validation
      nif: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(9, 'Must be exactly 9 digits')
        .max(9, 'Must be exactly 9 digits')
        .nullable()
        .transform((value) => (value === '' ? null : value)),
      niss: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(11, 'Must be exactly 11 digits')
        .max(11, 'Must be exactly 11 digits')
        .nullable()
        .transform((value) => (value === '' ? null : value)),
      sns: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(9, 'Must be exactly 9 digits')
        .max(9, 'Must be exactly 9 digits')
        .nullable()
        .transform((value) => (value === '' ? null : value)),
    })
    .required();

  const defaultEmail = onboarding ? email : user?.email || '';

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: defaultEmail,
      nif: user?.nif ?? '',
      sns: user?.sns ?? '',
      niss: user?.niss ?? '',
      birthDate: user?.birthDate ?? '',
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const keys = Object.keys(data);
    // TODO needs lots of cleanup once decisions are made on onboarding
    if (onboarding) {
      let input = {
        completedSteps,
        aboutYou,
        user: {},
      };

      keys.map((field) => {
        if (data[field] === null || data[field] === '') {
          //do nothing
        } else {
          input.user[field] = data[field];
        }
      });

      // only put these into the object if they are not null or false
      passport && (input.user.passport = passport);
      address && (input.user.address = address);
      driversLicense && (input.user.driversLicense = driversLicense);
      residencyCard && (input.user.residencyCard = residencyCard);
      banking && (input.user.bankAccount = banking);
      completeOnboarding(input);
      enqueueSnackbar(`${data.firstName} was successfully created`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else if (user) {
      // edit user
      let input = {};
      keys.map((field) => {
        if (data[field] === null || data[field] === '') {
          //do nothing
        } else {
          input[field] = data[field];
        }
      });
      input.userId = user.userId;
      // only put these into the object if they are not null or false
      passport && (input.passport = passport);
      address && (input.address = address);
      driversLicense && (input.driversLicense = driversLicense);
      residencyCard && (input.residencyCard = residencyCard);
      banking && (input.bankAccount = banking);

      editUser(input);

      // user with missing info
      if (user?.missingInfoArray?.length > 0) {
        // map over the redemptions with missing info
        user?.missingInfoArray?.map((redemption) => {
          // if the redemption is missing info, but not missing documents
          if (redemption.missingData[0] && !redemption.missingDocuments[0]) {
            // check them for missing data

            // check if they gave all that missing data
            const containsAllMissingData = redemption.missingData.every(
              (element) => {
                return Object.keys(input).includes(element);
              }
            );

            containsAllMissingData &&
              handleUserCompletesRedemptionStep({
                redemptionId: redemption.redemptionId,
              });
            enqueueSnackbar(
              `Thanks for uploading everything for ${data.firstName}'s ${redemption.service}. We're on it now!`,
              {
                variant: 'success',
                autoHideDuration: 2000,
              }
            );
          }
        });
      }

      enqueueSnackbar(`${data.firstName} was successfully updated`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    } else {
      //create user
      let input = {};
      keys.map((field) => {
        if (data[field] === null || data[field] === '') {
          // only put in
        } else {
          input[field] = data[field];
        }
      });
      passport && (input.passport = passport);
      address && (input.address = address);
      driversLicense && (input.driversLicense = driversLicense);
      residencyCard && (input.residencyCard = residencyCard);
      banking && (input.bankAccount = banking);
      editUser(input);
      enqueueSnackbar(`${data.firstName} was successfully created`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    navigate('/');
    setIsSubmitting(false);
  };

  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Box className={classes.paper}>
          <Typography variant="h5">{modalForm.header}</Typography>
          {modalForm.component}
        </Box>
      </Modal>
      <Box sx={{ m: 2 }} className={classes.cardContainer}>
        <form onSubmit={handleSubmit}>
          {['firstName', 'lastName', 'email'].map((thing, index) => (
            <React.Fragment key={index}>
              <Controller
                key={index}
                name={thing}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={camelCaseToLabel(thing)}
                    sx={{ width: '230px', m: 2 }}
                    error={Boolean(errors[thing])}
                    // disabled={thing === 'email' && onboarding}
                    helperText={
                      (errors[thing] && errors[thing]?.message) ||
                      (missingFields.includes(thing) && helperTexts[thing])
                    }
                    {...field}
                  />
                )}
              />
            </React.Fragment>
          ))}
          <Controller
            render={({ field }) => {
              return (
                <LocalizationProvider
                  adapterLocale={enGb}
                  dateAdapter={AdapterDateFns}
                >
                  <Stack
                    spacing={3}
                    sx={{ width: '230px', m: 2, display: 'inline-block' }}
                  >
                    <DatePicker
                      {...field}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      label={camelCaseToLabel('birthDate')}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.birthDate)}
                          helperText={
                            (errors.birthDate && errors.birthDate?.message) ||
                            (missingFields.includes('birthDate') &&
                              'Needed for nif')
                          }
                        />
                      )}
                      sx={{ width: '300px' }}
                    />
                  </Stack>
                </LocalizationProvider>
              );
            }}
            name="birthDate"
            control={control}
            onChange={([, data]) => data}
            defaultValue={new Date()}
          />
          <br />
          {['nif', 'sns', 'niss'].map((thing) => (
            <React.Fragment key={thing}>
              <Controller
                key={thing}
                name={thing}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={camelCaseToLabel(thing)}
                    sx={{ width: '230px', m: 2 }}
                    error={Boolean(errors[thing])}
                    helperText={
                      (errors[thing] && errors[thing]?.message) ||
                      (missingFields.includes(thing) && 'Needed for nif')
                    }
                    {...field}
                  />
                )}
              />
            </React.Fragment>
          ))}
          {/* <Box sx={{ m: 2 }}>
          <AddressAutocomplete />
        </Box> */}
          <br />
          {modalOptions.map((form, index) => {
            if (form.field) {
              return (
                <React.Fragment key={index}>
                  <DoneAllIcon sx={{ fill: '#438496' }} />
                  <span style={{ color: '#438496' }}>
                    {camelCaseToLabel(form.formName)}
                  </span>
                  <IconButton
                    onClick={() => {
                      setModalForm(form);
                      setOpenModal(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <br />
                </React.Fragment>
              );
            } else {
              if (missingFields.includes(form.formName)) {
                return (
                  <React.Fragment key={index}>
                    <Button
                      color="warning"
                      onClick={() => {
                        setModalForm(form);
                        setOpenModal(true);
                      }}
                    >
                      + {camelCaseToLabel(form.formName)} - needed for NIF
                    </Button>
                    <br />
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={index}>
                  <Button
                    onClick={() => {
                      setModalForm(form);
                      setOpenModal(true);
                    }}
                  >
                    + {camelCaseToLabel(form.formName)}
                  </Button>
                  <br />
                </React.Fragment>
              );
            }
          })}

          <br />
          {/* {!bankingInfo[0] && (
            <Button
              onClick={() => setBankingInfo((oldArray) => [...oldArray, {}])}
            >
              Add Banking
            </Button>
          )} */}
          {/* {bankingInfo.map((bank, index) => (
            <BankingForm
              index={index}
              bank={bank} bankingInfo={bankingInfo}
              setBankingInfo={setBankingInfo}
            />
          ))} */}
          <br />
          {!onboarding ? (
            <StyledFab
              disabled={isSubmitting}
              type="button"
              id="submit-form"
              aria-label="submit-form"
              variant="circular"
              onClick={handleSubmit(onSubmit)}
              className={classes.submitButton}
            >
              {isSubmitting ? <CircularProgress /> : <Save />}
            </StyledFab>
          ) : (
            <Button
              onClick={handleSubmit(onSubmit)}
              sx={{ float: 'right', position: 'relative', bottom: '-56px' }}
            >
              Complete
            </Button>
          )}
        </form>
      </Box>
    </Box>
  );
};
