import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Chip } from '@mui/material';
// import { useGetContactTags } from 'hooks/useGetContactTags';

export const ContactTagsAutocomplete = ({ tags, setTags, tagOptions }) => {
  const [allTags, setAllTags] = useState([]);
  const [newTag, setNewTag] = useState('');

  const handleEnterPress = (e) => {
    if (e.keyCode === 13 && newTag.trim()) {
      const newTags = [...new Set([...allTags, newTag])];
      setTags(newTags);
    }
  };
  const handleBlur = () => {
    if (newTag.trim()) {
      const newTags = [...new Set([...allTags, newTag])];
      setTags(newTags);
    }
  };
  const handleRemoveTag = (value) => {
    const filteredTags = [
      ...new Set(allTags.filter((tag) => tag !== value) || []),
    ];
    setTags(filteredTags);
  };

  useEffect(() => {
    setAllTags(tags);
  }, [tags]);

  return (
    <Autocomplete
      multiple
      id="item-tags"
      sx={{ m: 1, maxWidth: '500px' }}
      value={allTags}
      options={tagOptions}
      noOptionsText={'Press enter to create new tag'}
      onChange={(e, value) => {
        setTags(value);
      }}
      getOptionLabel={(option) => option}
      renderTags={(tagsValue) =>
        tagsValue.map((option, index) => (
          <Chip
            label={option}
            key={index.toString()}
            onDelete={() => handleRemoveTag(option)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Contact Tags'}
          fullWidth
          onKeyDown={handleEnterPress}
          onBlur={handleBlur}
          onChange={(e) => setNewTag(e.target.value)}
        />
      )}
    />
  );
};
