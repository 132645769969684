import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PAYMENT_INTENT } from 'graphql/queries';

export const useCreatePaymentIntent = () => {
  const [getPaymentIntent, { error, loading }] =
    useLazyQuery(GET_PAYMENT_INTENT);

  const handlePaymentIntent = (input) => {
    return getPaymentIntent({
      variables: { input },
    });
  };

  return {
    handlePaymentIntent,
    loading,
    error,
  };
};
