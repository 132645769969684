import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const PrivacyPolicy = () => {
  return (
    <Box p={6}>
      <Typography variant="h3" component="h1">
        <FormattedMessage id="privacy" defaultMessage="Privacy Policy" />
      </Typography>
      <Typography variant="p">Need to add a privacy policy</Typography>
    </Box>
  );
};
