import { useQuery } from '@apollo/react-hooks';
import { GET_REDEMPTIONS } from 'graphql/queries';

export const useGetRedemptions = () => {
  const { data: redemptions, loading } = useQuery(GET_REDEMPTIONS);

  return {
    redemptions: redemptions?.getRedemptions || [],
    loading,
  };
};
