import { useMutation } from '@apollo/react-hooks';
import { COMPLETE_REDEMPTION_STEP, GET_MASTER_USER } from 'graphql/queries';

export const useUserCompletesRedemptionStep = () => {
  const [completeRedemptionStep, { error, loading }] = useMutation(
    COMPLETE_REDEMPTION_STEP,
    {
      refetchQueries: [{ query: GET_MASTER_USER }], // refetch the user or at least change cache to onboarded
    }
  );

  const handleUserCompletesRedemptionStep = (input) => {
    return completeRedemptionStep({
      variables: { input },
    });
  };

  return {
    handleUserCompletesRedemptionStep,
    loading,
    error,
  };
};
