import React from 'react';
import { services } from 'utils/services';
import { ServiceCard } from './ServiceCard';
export const AddService = () => {
  return (
    <div>
      {services.map((service, index) => {
        return (
          <React.Fragment key={index}>
            <ServiceCard service={service} />
          </React.Fragment>
        );
      })}
    </div>
  );
};
