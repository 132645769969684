import React, { useState } from 'react';
import { Box, Alert, AlertTitle, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { camelCaseToLabel } from 'utils';
import { MissingInfoDisplay } from '../utils/MissingInfoDisplay';
export const MissingInfoAlert = ({ missingInfo }) => {
  const [alertOpen, setAlertOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      {alertOpen && (
        <Alert
          severity="warning"
          sx={{
            margin: 0,
            paddingLeft: 2,
            paddingRight: 2,
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: 'rgb(255, 244, 229) !important',
          }}
          onClose={() => setAlertOpen(false)}
        >
          <AlertTitle>
            Missing Information for {missingInfo.firstName}
            {`'s `} {camelCaseToLabel(missingInfo.service)}
          </AlertTitle>
          {missingInfo.missingDocuments && missingInfo.missingDocuments[0] && (
            <Box key={missingInfo.firstName}>
              <Box sx={{ display: 'inline' }}>
                <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                  Missing Documents:{' '}
                </Typography>{' '}
                <MissingInfoDisplay info={missingInfo.missingDocuments} />
              </Box>
              <Button
                onClick={() => navigate(`/missing-documents`)}
                variant="text"
                sx={{ display: 'inline', paddingLeft: 1 }}
                size="small"
              >
                UPLOAD
              </Button>
            </Box>
          )}

          {missingInfo.missingData && missingInfo.missingData[0] && (
            <Box sx={{ display: 'inline' }}>
              <Box sx={{ display: 'inline' }}>
                <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
                  {' '}
                  Missing Data:{' '}
                </Typography>
                <MissingInfoDisplay info={missingInfo.missingData} />
              </Box>

              <Button
                onClick={() => navigate(`/edit-user/${missingInfo.userId}`)}
                variant="text"
                sx={{ display: 'inline', paddingLeft: 1 }}
                size="small"
              >
                EDIT
              </Button>
            </Box>
          )}
        </Alert>
      )}
    </>
  );
};
