import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { CircularProgress, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCreatePurchase } from 'hooks/useCreatePurchase';

export const StripeCheckout = ({ purchaseInput, clientSecret }) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const { handleCreatePurchase } = useCreatePurchase();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // test number 4242 4242 4242 4242
  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (!clientSecret) {
      return;
    }
    if (submitted) {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Payment succeeded!');
            break;
          case 'processing':
            setMessage('Your payment is processing.');
            break;
          case 'requires_payment_method':
            setMessage('Your payment was not successful, please try again.');
            break;
          default:
            setMessage('Something went wrong.');
            break;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, clientSecret]);

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const res = await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: 'https://localhost:3000/',
        },
        redirect: 'if_required',
      })
      .then(function (result) {
        if (result?.paymentIntent?.status === 'succeeded') {
          const input = {
            service: purchaseInput.service,
            quantityPurchased: purchaseInput.quantity,
            price: purchaseInput.price,
          };

          handleCreatePurchase(input); // sending to api with redeemed set to false
          const { currency } = result.paymentIntent;
          // const { amount, currency } = result.paymentIntent;

          enqueueSnackbar(
            `Successfully paid ${
              purchaseInput.price * purchaseInput.quantity
            } ${currency} for ${purchaseInput.quantity} ${
              purchaseInput.service
            }`,
            {
              variant: 'success',
              autoHideDuration: 2000,
            }
          );
          navigate('/services');
        }
        if (result.error) {
          // Inform the customer that there was an error.
          setMessage(result.error.message);
        }
      });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      (res.error && res.error?.type === 'card_error') ||
      (res.error && res.error?.type === 'validation_error')
    ) {
      // add toast message with error
      setMessage(res.error.message);
    } else {
      // add toast message with error
      setMessage('An unexpected error occurred.');
    }
    // if !error do toast message with successful payment

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {(!isLoading || stripe || elements) && (
        <Button
          disabled={isLoading || !stripe || !elements}
          variant="contained"
          id="submit"
          onClick={() => handleSubmit()}
          sx={{ marginTop: '10px', backgroundColor: '#438496' }}
        >
          <span id="button-text">
            {isLoading || !stripe || !elements ? (
              <CircularProgress />
            ) : (
              'Pay now'
            )}
          </span>
        </Button>
      )}

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};
