import React from 'react';
import { Box, Typography } from '@mui/material';
import { UploadDocument } from './UploadDocument';
import { camelCaseToLabel } from 'utils';

export const UserService = ({ missingInfo }) => {
  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h5">
        Missing documents for {missingInfo.firstName}'s{' '}
        {camelCaseToLabel(missingInfo.service)}
      </Typography>
      {missingInfo.missingDocuments.map((missingDocument, index) => {
        return (
          <Box key={index}>
            <UploadDocument
              missingInfo={missingInfo}
              missingDocument={missingDocument}
            />
          </Box>
        );
      })}
    </Box>
  );
};
