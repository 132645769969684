// Get User
// Get User Documents
// Approve button -> does next step, routes back to redemptions and refetch getRedemptions
import React from 'react';
import { useGetUserDocuments } from 'hooks/useGetUserDocuments';
import { Typography, Box, Button } from '@mui/material';
import { useGetUser } from 'hooks/useGetUser';
import { FilePreview } from './FilePreview';
import { useCompleteRedemptionStep } from 'hooks/useCompleteRedemptionStep';

export const ReviewDocuments = ({
  userId,
  cognitoId,
  redemptionId,
  currentStep,
}) => {
  const input = { userId, cognitoId };
  const { handleCompleteRedemptionStep } = useCompleteRedemptionStep();
  const { user } = useGetUser(input);
  const { userDocuments } = useGetUserDocuments(input);
  const [submitted, setSubmitted] = React.useState(false);

  const fieldObjects = ['address', 'passport', 'license', 'residencyCard'];

  return (
    <>
      {!submitted && user.firstName && userDocuments[0] && (
        <>
          {currentStep.requiredDocuments[0] &&
            currentStep.requiredDocuments.map((doc) => (
              <Box sx={{ display: 'inline' }}>
                <Typography variant="h5">{doc}</Typography>
                <FilePreview
                  doc={doc}
                  userDocuments={userDocuments}
                ></FilePreview>
                <br />
                <br />
              </Box>
            ))}
          {currentStep.requiredFields[0] &&
            currentStep.requiredFields.map((field) => (
              <div>
                {!fieldObjects.includes(field) && (
                  <Box>
                    <Typography sx={{ display: 'inline', fontWeight: 'bold' }}>
                      {field}:
                    </Typography>
                    <Typography sx={{ display: 'inline', fontStyle: 'italic' }}>
                      {user[field]}
                    </Typography>
                  </Box>
                )}
                {fieldObjects.includes(field) && (
                  <>
                    <br />
                    <Typography variant="h5">{field}</Typography>
                    {Object.keys(user[field]).map((key) => (
                      <Box>
                        <Typography
                          sx={{ display: 'inline', fontWeight: 'bold' }}
                        >
                          {key}:
                        </Typography>
                        <Typography
                          sx={{ display: 'inline', fontStyle: 'italic' }}
                        >
                          {user[field][key]}
                        </Typography>
                      </Box>
                    ))}
                    <br />
                  </>
                )}
              </div>
            ))}
          <Button
            onClick={() => {
              handleCompleteRedemptionStep({ redemptionId: redemptionId });
              setSubmitted(true);
            }}
          >
            Approve Documents
          </Button>
        </>
      )}
    </>
  );
};
