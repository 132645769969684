import { makeStyles, withStyles } from '@mui/styles';
import { TableCell } from '@mui/material';

export const useStyles = makeStyles(() => ({
  errors: {
    color: 'red',
  },
}));

export const StyledCell = withStyles((theme) => ({
  root: {
    padding: '8px !important',
  },
}))(TableCell);
