export const services = [
  {
    name: 'nif',
    label: 'NIF Package',
    active: true,
    cost: 99,
    when: 'A NIF is a tax identification number that is used almost daily throughout Portugal. You will need it for things like a lease, car, employment.',
    requiredDocuments: ['passport', 'proofOfAddressOrigin'],
    timeEstimate: '30 days',
    article: 'https://www.landing.newlocals.eu/post/what-is-a-nif',
    articleTitle: 'What is a NIF?',
    image:
      'http://ate-si.com/cms/wp-content/uploads/2017/05/logo-finan%C3%A7as.jpg',
    firstStep: {
      description: 'Initial documents and data',
      requiredDocuments: ['passport', 'proofOfAddressOrigin'], // should proof of address be in home country?
      requiredFields: [
        'firstName',
        'lastName',
        'email',
        'passport',
        'birthDate',
        'address',
      ],
      responsibleForAction: 'user',
    },
  },

  {
    name: 'apartment',
    serviceId: 'apartment-rental',
    label: 'Apartment Rental (coming soon)',
    cost: 199,
    active: false,
    // when: 'A NIF is a tax identification number that is used almost daily throughout Portugal. You will need it for things like a lease, car, employment. Read more **here** to understand everyhting a NIF is used for.',

    provides: [
      'Review/recommendations on apartment lease',
      'Setup of utilities (gas, water, electricity, internet/cable)',
      'Intro to maid service',
      'Intro to moving service',
    ],
    timeEstimate: '1-2 days',
    image:
      'https://images.unsplash.com/photo-1574362848149-11496d93a7c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1384&q=80',
    steps: [],
  },
  {
    name: 'relocation',
    serviceId: 'full-relocation',
    label: 'Relocation package (coming soon)',
    cost: 499,
    provides: [
      'Apartment package',
      'NIF package',
      'NISS (social security)',
      'SNS (health number)',
      'WhatsApp support line (responses 8am-7pm)',
      'Access to community Discord',
      'Intro to  trusted contacts (accountant, lawyer, etc)',
    ],
    // timeEstimate: '1-2 days',
    image:
      'https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    steps: [],
  },
  {
    name: 'drivers-license',
    label: 'Drivers License (coming soon)',
    active: false,
    cost: 199,
    when: 'Within first 6 months of moving. You will need to trade in your current license',
    timeEstimate: '30 days',
    requiredDocuments: [
      'Passport',
      'Apostilled Drivers Record',
      'Portuguese Residency Card',
      'Medical Certificate',
    ],
    article: 'https://www.landing.newlocals.eu/post/exchange-license',
    articleTitle: 'Do I need to exchange drivers license?',
    image:
      'https://images.unsplash.com/photo-1489824904134-891ab64532f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1931&q=80',
    steps: [],
  },
  // {
  //   name: 'nhr',
  //   label: 'NHR (coming soon)',
  //   cost: 50,
  //   when: 'After you have your NIF. You will need to be within certain sectors.',
  //   timeEstimate: '1 day',
  //   active: false,
  //   article: 'https://www.landing.newlocals.eu/post/what-is-nhr',
  //   articleTitle: 'What is NHR and how do I get it?',
  //   steps: [],
  //   image:
  //     'https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3411&q=80',
  // },
];
