import React, { useState } from 'react';
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { UserDocs } from './UserDocs/UserDocs';
import { UserInfo } from './UserInfo';
import { UserNumbers } from './UserNumbers';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

export const Profile = ({ masterUser = {} }) => {
  const { users } = masterUser;
  const navigate = useNavigate();
  const [page, setPage] = useState('UserNumbers');
  const [user, setUser] = useState(users[0] || {});

  const updatePage = (event, page) => {
    page && setPage(page);
  };

  const updateUser = (event, user) => {
    setUser(user.props.value);
  };

  return (
    <Box
      px={4}
      py={2}
      sx={{ marginBottom: '48px' }}
      width={{ xs: '100vw', md: '75vw' }}
    >
      {users[0] ? (
        <Select
          sx={{ width: '165px', mb: 2, mr: 1 }}
          labelId="user-label"
          id="user"
          defaultValue={user}
          value={user}
          onChange={updateUser}
          renderValue={() => {
            return `${user.firstName} ${user.lastName}`;
          }}
          inputProps={{ 'aria-label': 'user' }}
        >
          {users.map((user, index) => (
            <MenuItem value={user} key={index}>
              {user.firstName} {user.lastName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Button onClick={() => navigate('/add-user')}>
          Add your first user
        </Button>
      )}

      {page !== 'UserDocs' && users[0] && (
        <>
          <Button
            sx={{ width: '20px', mr: 1, mt: 0 }}
            onClick={() => navigate(`/edit-user/${user.userId}`)}
          >
            <EditIcon
              sx={{
                fill: '#286DA8',
              }}
            />
            Edit
          </Button>
          <Button onClick={() => navigate('/add-user')}>+Add</Button>
        </>
      )}
      <br />
      {users[0] && (
        <>
          <ToggleButtonGroup
            value={page}
            exclusive
            onChange={updatePage}
            aria-label="text alignment"
            sx={{
              '& .Mui-selected': {
                backgroundColor: 'rgba(255, 218, 185, 0.5)',
              },
            }}
          >
            <ToggleButton
              sx={{
                '& .Mui-selected': {
                  backgroundColor: 'rgba(255, 218, 185, 0.5)',
                },
              }}
              value="UserNumbers"
              aria-label="left aligned"
            >
              Numbers
            </ToggleButton>
            <ToggleButton
              sx={{
                '& .Mui-selected': {
                  backgroundColor: 'rgba(255, 218, 185, 0.5)',
                },
              }}
              value="UserInfo"
              aria-label="left aligned"
            >
              Info
            </ToggleButton>
            <ToggleButton
              sx={{
                '& .Mui-selected': {
                  backgroundColor: 'rgba(255, 218, 185, 0.5)',
                },
              }}
              value="UserDocs"
              aria-label="left aligned"
            >
              Docs
            </ToggleButton>
          </ToggleButtonGroup>
          {page === 'UserNumbers' && <UserNumbers user={user} />}
          {page === 'UserInfo' && <UserInfo user={user} />}
          {page === 'UserDocs' && <UserDocs user={user} />}
        </>
      )}
    </Box>
  );
};
