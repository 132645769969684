import { createTheme } from '@mui/material/styles';
import { regular, mono } from './fonts';

const theme = createTheme({
  palette: {
    // todo - we should manage colors here. Use './colors'
    primary: {
      main: '#nnnnnn',
      blue: '#286DA8',
      peach: '#CD5360',
      field: '#B374E',
      breeze: '#438496',
      grey: 'grey',
      peachPuff: '#ffdab9',
    },
    success: {
      main: '#438496',
      light: '#438496',
      dark: '#438496',
    },
  },

  typography: {
    fontFamily: `${regular}, ${mono}`,
    fontSize: 40,
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          '&.MuiSelect-root': {
            backgroundColor: '#000',
            color: '#fff',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        root: {
          '&.MuiTooltip-popper': {
            fontSize: '2em',
            color: 'yellow',
            backgroundColor: 'red',
          },
        },
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [regular, mono],
      },
    },
    MuiPickersToolbar: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        height: '50px !important',
        marginBottom: '50px',
        paddingTop: '0px',
        '& .MuiTypography-h4': {
          fontSize: '30px',
          textAlign: 'center',
        },
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
        '& .MuiGrid-container': {
          justifyContent: 'center',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '2em',
        color: 'yellow',
        backgroundColor: 'red',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#438496',
          color: '#438496',
        },
        '&:hover': {
          backgroundColor: '#0069d9',
          borderColor: '#0062cc',
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: '#0062cc',
          borderColor: '#005cbf',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
      },
    },
  },
});

export default theme;
