import React from 'react';
import { useStyles } from './ServiceModalStyles.js';
import { Typography, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { camelCaseToLabel } from 'utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SimpleTooltip } from '../../SimpleTooltip';

export const ServiceModal = ({ service, setOpenModal }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const bigScreen = useMediaQuery('(min-height:550px)');

  const tooltipText =
    'Proof of address must be address outside Portugal and clearly state your name. Common documents include drivers license, utilities (within past 3 months), bank statement (within last 3 months)';
  return (
    <Card sx={{ width: 425, m: 4, display: 'inline-block' }} key={service.name}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {service.label}
        </Typography>
        <Typography variant="h6" color="text.secondary">
          Cost: €{service.cost}
        </Typography>
        {bigScreen && service.when && (
          <>
            <Typography variant="h5" color="text.secondary">
              When to complete:
            </Typography>
            <Typography variant="paragraph" color="text.secondary">
              {service.when}
            </Typography>
          </>
        )}
        {bigScreen && <br />}
        {service.article && (
          <>
            <Typography
              sx={{ display: 'inline' }}
              variant="h5"
              color="text.secondary"
            >
              Blog:{' '}
            </Typography>
            <a href={service.article} target="_blank" rel="noreferrer">
              {' '}
              {service.articleTitle}
            </a>
          </>
        )}

        {service.provides && (
          <>
            <Typography variant="h5" color="text.secondary">
              Provides:
            </Typography>

            <ul
              style={{
                marginBlockStart: '4px',
                marginBlockEnd: '4px',
              }}
            >
              {service.provides.map((doc, index) => (
                <li key={index}>
                  <Typography variant="paragraph" color="text.secondary">
                    {doc}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        )}
        {service.requiredDocuments && (
          <>
            <Typography variant="h5" color="text.secondary">
              Required Documents:
            </Typography>

            <ul
              style={{
                marginBlockStart: '4px',
                marginBlockEnd: '4px',
              }}
            >
              {service.requiredDocuments.map((doc, index) => (
                <li key={index}>
                  <Typography variant="paragraph" color="text.secondary">
                    {camelCaseToLabel(doc)}
                    {doc === 'proofOfAddressOrigin' && (
                      <SimpleTooltip text={tooltipText} />
                    )}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        )}

        {bigScreen && service.timeEstimate && (
          <Typography variant="h5" color="text.secondary">
            Estimated Time: {service.timeEstimate}
          </Typography>
        )}
      </CardContent>
      <div className={classes.buttonSection}>
        <Button
          variant="text"
          color="error"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          CLOSE
        </Button>
        {service.name === 'b-2-b' ? (
          <a href="mailto: nickhall122@gmail.com">
            <Button variant="text">Contact us</Button>
          </a>
        ) : (
          <Button
            variant="text"
            disabled={!service.active}
            onClick={() => navigate(`/get-service/${service.name}`)}
          >
            GET STARTED!
          </Button>
        )}
      </div>
    </Card>
  );
};
